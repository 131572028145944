import React from "react";
import { Router } from "@reach/router";
import { configure } from "axios-hooks";
import Axios from "axios";
import ReactGA from 'react-ga';
import { ApolloProvider } from '@apollo/client';

import './i18n'
import './App.css';
import Home from './views/Home'
import Locked from './views/Locked'
import Login from './views/Login'
import AuthenticatedRoute from './components/AuthenticatedRoute'
import { SchoolStateProvider } from './context/SchoolContext'
import { UserProvider } from './context/UserContext'
import Waiver from './views/Waiver'
import NotFound from './views/NotFound'
import InternalError from './views/InternalError'
import SHIP from './views/SHIP'
import Supplemental from './views/Supplemental'
import PendingWaiver from './views/PendingWaiver';
import SsoRedirect from './views/SsoRedirect';
import { client } from './client'

const axios = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

configure({ axios });

const SchoolParamRoute = ({ children }) => <div>{children}</div>;

function App() {
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLE && process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
      siteSpeedSampleRate: 100,
    });
  } else {
    console.log('Google Analytics disabled')
  }

  ReactGA.event({
    category: 'User',
    action: 'Open App'
  });

  return (
    <div className="App">
      <ApolloProvider client={client}>
        <UserProvider>
          <SchoolStateProvider>
            <Router>
              <SchoolParamRoute path='/:school'>
                <Login path='/log-in' />
                <SsoRedirect path='/sso' />
                <Locked path='/locked' />
                <AuthenticatedRoute path='/' component={<Home />} />
                <AuthenticatedRoute path='/waiver' component={<Waiver />} />
                <AuthenticatedRoute path='/update-waiver' component={<PendingWaiver />} />
                <AuthenticatedRoute path='/ship' component={<SHIP />} />
                <AuthenticatedRoute path='/supplemental' component={<Supplemental />} />
                <InternalError path='/500' />
                <NotFound default />
              </SchoolParamRoute>
              <NotFound default />
            </Router>
          </SchoolStateProvider>
        </UserProvider>
      </ApolloProvider>
    </div>
  );
}

export default App;
