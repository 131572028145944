import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/core";
import { UserContext } from "../../context/UserContext";
import { SchoolStateContext } from "../../context/SchoolContext";
import { logout } from '../../services/AuthService';
import CardHeader from '../CardHeader/index';

const Header = ({ hideLogoInMobile }) => {
  const { t } = useTranslation();
  const [user, setUser] = useContext(UserContext);
  const school = useContext(SchoolStateContext) || {};

  return (
    <header
      className="d-md-flex text-center text-md-right flex-md-row justify-content-between align-items-end position-relative"
      css={css`
        background-color: #fdfdfd;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.49);
        padding: 0 20px 20px;
        z-index: 1;
        @media (min-width: 768px) {
          padding: 20px 20px 20px 70px;
        }
      `}
    >
      <img
        css={css`
          height: 55px;
          width: auto;
          align-self: center;

          @media (min-width: 768px) {
            align-self: flex-start;
          }
        `}
        src={school.school && school.school.portal_logo}
        alt="logo"
        className={`mt-2 ${hideLogoInMobile ? 'd-none d-md-block' : ''}`}
      />
      <div>
        {user && !!Object.keys(user).length && (
          <div className="text-right">
            <button
              onClick={() => logout(school.school.url_slug, setUser, school.method === 'sso' ? school.auth_config.logout : null)}
              css={css`
              color: #465161;
              background-color: #ffffff;
            `}
              className="border-0 py-2"
            >
              <span>
                <i className="ti-power-off mr-2"></i>
                <strong>{t("LOG_OUT")}</strong>
              </span>
            </button>
          </div>
        )}
        {user && (
          <h3 className="d-none d-md-block mb-0">
            {user.first_name} {user.last_name}
          </h3>
        )}
        <div
          className="d-none d-md-block"
          css={css`
            position: absolute;
            bottom: -44px;
            right: 20px;
          `}
        >
          <ul>
            <li

              className='dropdown d-none d-md-block'>
              <span
                css={css`background-color: #3d4554`}
                tabIndex='0' data-toggle='dropdown' className="py-2 px-3 text-white cursor-pointer">
                {t('HELP')}?
              </span>

              <div
                className='dropdown-menu open-top-right dropdown-menu-right'
                x-placement='bottom-end'
                style={{
                  position: 'absolute',
                  top: '65px',
                  left: '-210px',
                  willChange: 'top, left',
                }}
              >
                <CardHeader title={`${t('HELP')}?`} />
                <div className="card-body">
                  <p className="m-0">{t('HELP_DESCRIPTION')}</p>
                  <ul>
                    <li>
                      <a
                        className="text-primary"
                        href="mailto:student-insurance@ahpcare.com"
                        target="_blank"
                        rel='noreferrer noopener'>
                        <strong>student-insurance@ahpcare.com</strong>
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-primary"
                        href="tel:877-974-7462"
                        target="_blank"
                        rel='noreferrer noopener'>
                        <strong>877-974-7462</strong>
                      </a> Ext. 315
                  </li>
                  </ul>
                  <p>{t('INCLUDE_YOUR')} <strong>{t('STUDENT_ID')}</strong> {t('IN_ALL_YOUR_MESSAGES')}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
