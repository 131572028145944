import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

const HelpComponent = () => {
  const { t } = useTranslation();
  const [displayDetails, setDisplayDetails] = useState(false);
  return (
    <div className="callout callout-info card-shadowed">
      <p className="mb-2"><strong>{t("HELP")}?</strong></p>
      <label className="" onClick={() => setDisplayDetails(d => !d)}>
        {t('VIEW_DETAILS')}

        <i className={`fa fa-angle-${displayDetails ? 'up' : 'down'} ml-2`}></i>

      </label>
      {displayDetails && (
        <>
          <p>{t('HELP_DESCRIPTION')}</p>
          <ul>
            <li>
              <a
                className="text-primary"
                href="mailto:student-insurance@ahpcare.com"
                target="_blank"
                rel='noreferrer noopener'>
                <strong>student-insurance@ahpcare.com</strong>
              </a>
            </li>
            <li>
              <a
                className="text-primary"
                href="tel:877-974-7462"
                target="_blank"
                rel='noreferrer noopener'>
                <strong>877-974-7462</strong>
              </a> Ext. 315
            </li>
          </ul>
          <p>{t('INCLUDE_YOUR')} <strong>{t('STUDENT_ID')}</strong> {t('IN_ALL_YOUR_MESSAGES')}</p>
        </>
      )}
    </div>
  )
}

export default HelpComponent
