import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { css } from '@emotion/core'

const Modal = ({ inputCSS, dialogCSS, targetId, children, display, setDisplay, clickOutside = false }) => {
  const handleClickOutside = e => {
    if (e.target === e.currentTarget && clickOutside) {
      setDisplay(false)
    }
  }

  return (
    <CSSTransition
      unmountOnExit
      in={display}
      timeout={300}
      classNames='animate-modal'
    >
      <div
        css={css`
          background-color: #00000033;
          ${inputCSS ? inputCSS : ''}
        `}
        className={`modal modal-center fade d-block show`}
        id={targetId}
        tabIndex='-1'
        aria-modal='true'
        onClick={handleClickOutside}
      >
        <div
          css={css`
            ${dialogCSS ? dialogCSS : ''}
          `}
          className="modal-dialog"
        >
          <div
            className="modal-content card card-shadowed mx-auto"
          >
            {children}
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default Modal
