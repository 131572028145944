import React, { useState, useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactGA from 'react-ga';
import { navigate } from '@reach/router';

import StepOne from "../components/SHIP/StepOne";
import StepTwo from "../components/SHIP/StepTwo";
import StepThree from "../components/SHIP/StepThree";
import { SchoolStateContext } from "../context/SchoolContext";
import { useAuthorizedAxios } from "../hooks/use-axios";
import { UserContext } from "../context/UserContext";
import Layout from "../components/Layout";
import { logout } from "../services/AuthService";
import Container from '../components/Container/index';
import CustomWizard from '../components/CustomWizard/index';
import Preloader from '../components/Preloader/index';
import SubmitErrorModal from '../components/SubmitErrorModal/index';

const SHIP = () => {
  const { t } = useTranslation();
  const { school } = useContext(SchoolStateContext);
  const [, setUser] = useContext(UserContext);
  const [errorModal, setErrorModal] = useState(false);
  const [data, setData] = useState({
    step: 0,
    valid: true,
    acknowledge: false,
  });
  const [{ data: postData, error, loading }, executePost] = useAuthorizedAxios(
    {
      url: "enrollments",
      method: "POST",
      manual: true
    },
  );

  useEffect(() => {
    ReactGA.pageview('/ship')
  }, [])

  const submitShip = useCallback(() => {
    executePost();
    ReactGA.event({
      category: 'User',
      action: 'Press Submit Enrollment'
    });
  }, [executePost])

  useEffect(() => {
    if (postData && !error && !loading) {
      setData(d => ({
        ...d,
        step: d.step + 1,
      }));
    } else if (error && !loading) {
      setErrorModal(true);
    }
  }, [postData, error, loading])

  const goHome = useCallback(() => {
    navigate(`/${school.url_slug}`);
  }, [school])

  const goBack = useCallback(() => {
    setData(d => ({
      ...d,
      valid: true,
      step: d.step - 1,
    }))
  }, [])

  const handleFinish = useCallback(() => {
    logout(school.url_slug, setUser, school.method === 'sso' ? school.auth_config.logout : null)
  }, [school, setUser])

  const handleNext = useCallback(() => {
    setData(data => ({
      ...data,
      step: data.step + 1,
      valid: data.step === 0 ? data.acknowledge : true,
    }));
  }, [])


  const SHIPItems = useMemo(() => [
    {
      title: "1",
      id: "ship-first-step",
      content: <StepOne />,
      backButton: (
        <button
          className='btn btn-secondary'
          onClick={goHome}
        >
          {t('BACK')}
        </button>
      ),
      nextButton: (
        <button
          className='btn btn-secondary'
          onClick={handleNext}
          disabled={!data || (data && !data.valid)}
        >
          {t('NEXT')}
        </button>
      ),
    },
    {
      title: "2",
      id: "ship-second-step",
      content: <StepTwo />,
      backButton: (
        <button
          className='btn btn-secondary'
          onClick={goBack}
        >
          {t('BACK')}
        </button>
      ),
      nextButton: (
        <button
          className='btn btn-primary btn-label'
          onClick={submitShip}
          disabled={!data || (data && !data.valid) || loading}
        >
          <label>
            <i className='ti-check'></i>
          </label>
          {t('SUBMIT_SHIP_APPLICATION')}
        </button>
      ),
    },
    {
      title: "3",
      id: "ship-third-step",
      content: <StepThree />,
      backButton: null,
      nextButton: (
        <button
          className='btn btn-secondary'
          onClick={handleFinish}
        >
          {t('LOG_OUT')}
        </button>
      ),
    },
  ], [data, goBack, goHome, handleFinish, handleNext, submitShip, t, loading]);


  return (
    <Layout hideLogoInMobile>
      <Container>
        <SubmitErrorModal
          display={errorModal}
          setDisplay={setErrorModal}
          title={t('SHIP_SUBMISSION_NOTIFICATION')}
          description={t('SHIP_SUBMISSION_ERROR')}
        />
        {loading && <Preloader className="d-flex bg-transparent" />}
        <CustomWizard
          title={t('SHIP_ENROLLMENT')}
          items={SHIPItems}
          context={[data, setData]}
        />
      </Container>
    </Layout>
  );
};

export default SHIP;
