import React, { useContext, useCallback } from "react";
import { css } from "@emotion/core";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { SchoolStateContext } from "../../context/SchoolContext";
import { WizardContext } from "../../context/WizardContext";
import Checkbox from '../Checkbox/index';

const StepTwo = () => {
  const { t } = useTranslation();
  const { enrollment_segment, period, school } = useContext(SchoolStateContext);
  const { data, setData } = useContext(WizardContext);

  const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleCheckBox = useCallback(() => {
    setData(d => ({
      ...d,
      acknowledge: !d.acknowledge,
      valid: !d.acknowledge
    }));
  }, [setData])

  return (
    <div
      className="card"
      css={css`
        box-shadow: none;
      `}
    >
      <div>
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            margin-bottom: 90px;
            @media (max-width: 768px) {
              margin-bottom: 24px;
            }
            span {
              font-size: 14px;
              color: #4d5259;
              font-weight: normal;
              &.field-name {
                @media (max-width: 420px) {
                  font-size: 10px;
                  color: #a8a9ae;
                }
              }
            }
          `}
        >
          <span className="field-name fw-500">{t("ENROLLMENT_PERIOD")}</span>
          <span className="fw-500">{enrollment_segment.segment_label} {period.academic_year}</span>
          <span className="field-name fw-500">{t("EFFECTIVE_AND_TERMINATION_DATES")}</span>
          <span className="fw-500">{moment(enrollment_segment.coverage_start_date).format('MM/DD/YYYY')} - {moment(enrollment_segment.coverage_end_date).format('MM/DD/YYYY')}</span>
          <span className="field-name fw-500">{t("ENROLLMENT_DEADLINE")}</span>
          <span className="fw-500">{moment(enrollment_segment.enrollment_deadline).format('MM/DD/YYYY')}</span>
          <span className="field-name fw-500">{t("PREMIUM")}</span>
          <span className="fw-500">${numberWithCommas(enrollment_segment.price)}</span>
        </div>
        <div>
          <p
            css={css`
              margin-bottom: 8px;
              font-size: 14px;
              color: #4d5259;
              font-weight: normal;
            `}
            className="p-body"
          >
            {t("I_UNDERSTAND_THAT")}:
          </p>
          <ul
          css={css`
            padding-left: 40px;
            @media (max-width: 768px) {
              padding-left: 16px;
            }`}
          >
            <li>
              {t('SHIP_STEP_2_ITEM1_P1')} {school.school_insurance_name} {t('SHIP_STEP_2_ITEM1_P2')}
            </li>
            <li>
              {t('SHIP_STEP_2_ITEM2')}
            </li>
            <li>
              {t('SHIP_STEP_2_ITEM3_P1')} {moment(enrollment_segment.enrollment_deadline).format('MM/DD/YYYY')}{t('SHIP_STEP_2_ITEM3_P2')}
            </li>
            {enrollment_segment.sequence === 0 && (
              <li>
                {t('SHIP_STEP_2_ITEM4')}
              </li>
            )}
          </ul>
          <div className="custom-control custom-control-lg custom-checkbox pt-3 pt-md-0">
            <Checkbox 
              checked={data.acknowledge} 
              onCheck={handleCheckBox} 
              label={t("I_ACKNOWLEDGE_THE_CONDITIONS_ABOVE")}
              labelStyle="fs-14 fw-500"
              />
            {/* <input
              checked={data.acknowledge}
              // onInput={handleCheckBox}
              onChange={handleCheckBox}
              type="checkbox"
              className="mr-2 mt-1"
              id="acknowledge"
            />
            <label className="" htmlFor="acknowledge">
              {t("I_ACKNOWLEDGE_THE_CONDITIONS_ABOVE")}
            </label> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
