import React from "react";

const FileRow = ({ name, extension, downloadLink }) => {
  return (
    <p className="mb-1">
      <i
        className={`mr-3 text-muted fs-18 fa fa-file-${
          extension === 'pdf'
            ? 'pdf'
            : 'photo'
          }-o`}
      ></i>
      <span>{name}</span>
      <a
        href={downloadLink}
        target="_blank"
        rel='noreferrer noopener'
        className="btn border-0 bg-transparent text-default"
      >
        <i className='fa fa-download'></i>
      </a>
    </p>
  );
};

export default FileRow;
