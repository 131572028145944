import React from 'react'
import { css } from '@emotion/core';
import IdleTimer from 'react-idle-timer'
import { useTranslation } from "react-i18next";
import { logout } from '../../services/AuthService';

import { UserContext } from "../../context/UserContext";
import { SchoolStateContext } from "../../context/SchoolContext";

import Header from '../Header'
import Footer from '../Footer'
import Modal from '../Modal/index';
import CardHeader from '../CardHeader';

const Layout = ({ withIdle = true, withHeader = true, children, hideFooterResponsive = true, hideLogoInMobile = false }) => {
  const { t } = useTranslation();
  const [user, setUser] = React.useContext(UserContext);
  const school = React.useContext(SchoolStateContext) || {};
  const [display, setDisplay] = React.useState(false);
  const lockScreenTime = 300000 // 5 mins
  const lockScreenTime15 = 900000 //15 mins

  const handleLock = () => {
    // If I went idle with a modal open, remove backdrop
    const modalBackdrop = document.querySelector('.modal-backdrop')
    if (modalBackdrop) {
      modalBackdrop.remove()
    }
    setDisplay(true);
  }

  const handleLogOut = () => {
    logout(school.school.url_slug, setUser, school.method === 'sso' ? school.auth_config.logout : null, true)
  }

  const closeCancelModal = async () => {
    setDisplay(false)
  }

  return (
    <div css={css`
      height: 100vh;
      display: flex;
      flex-direction: column;
      `}>
      {withIdle
        && (<>
          <IdleTimer timeout={lockScreenTime} onIdle={handleLock} />
          <IdleTimer timeout={lockScreenTime15} onIdle={handleLogOut} />
          <Modal dialogCSS={'width:322px !important'} targetId='lock5-modal' display={display} setDisplay={setDisplay}>
            <CardHeader
              title={t('INACTIVITY')}
              rightComponent={(
                <button className='btn border-0 p-0 bg-transparent' onClick={closeCancelModal}>
                  <i className='fa fa-close'></i>
                </button>
              )} />
            <div className='card-body'>
              <p className='mb-4' css={css`font-size: 13px;`}>
                {t('INACTIVE_5_MINUTES')}
              </p>
              <div className='d-flex justify-content-end'>
                <button
                  onClick={closeCancelModal}
                  className='btn btn-bold btn-primary btn-label'
                >
                  <label>
                    <i className='ti-check'></i>
                  </label>
                  {t('CONTINUE')}
                </button>
              </div>
            </div>
          </Modal>
        </>
        )}
      {withHeader && <Header hideLogoInMobile={hideLogoInMobile} />}
      <div
        css={css`
          flex-grow: 1;
      `}
      >
        {children}
      </div>
      <Footer hideFooterResponsive={hideFooterResponsive} />
    </div>
  )
}

export default Layout
