import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

export default function LoginError({ error }) {
  const { t } = useTranslation();
  const [displayDetails, setDisplayDetails] = useState(false);

  let title = '';
  switch (error.message) {
    case 'Currently Not Eligible':
      title = 'CURRENTLY_NOT_ELIGIBLE';
      break;
    case 'Student ID Not Recognized':
      title = 'STUDENT_ID_NOT_RECOGNOZED';
      break;
    case 'Your Entries Do Not Match Our Records':
      if (!error.validation.dateOfBirth && !error.validation.lastName) {
        title = 'YOUR_ENTRIES_NOT_MATCH';
      } else if (error.validation.dateOfBirth && !error.validation.lastName) {
        title = 'LAST_NAME_NOT_MATCH';
      } else if (!error.validation.dateOfBirth && error.validation.lastName) {
        title = 'DATE_OF_BIRTH_NOT_MATCH';
      }
      break;
    default:
      break;
  }

  const color = error.message === 'Currently Not Eligible' ? 'info' : 'danger';
  let details = '';

  switch (error.message) {
    case 'Student ID Not Recognized':
    case 'Currently Not Eligible':
      details = 'YOU_MUST_BE_REGISTERED'
      break;
    default:
      details = 'TRY_AGAIN_OR_CONTACT'
      break;
  }

  return (
    <div className={`callout callout-${color} card-shadowed`}>
      <p className="mb-2"><strong>{t(title)}</strong></p>
      <label className="" onClick={() => setDisplayDetails(d => !d)}>
        {t('VIEW_DETAILS')}

        <i className={`fa fa-angle-${displayDetails ? 'up' : 'down'} ml-2`}></i>

      </label>
      {displayDetails && (
        <>
          <p>{t(details)}</p>
          <ul>
            <li>
              <a
                className="text-primary"
                href="mailto:student-insurance@ahpcare.com"
                target="_blank"
                rel='noreferrer noopener'>
                <strong>student-insurance@ahpcare.com</strong>
              </a>
            </li>
            <li>
              <a
                className="text-primary"
                href="tel:877-974-7462"
                target="_blank"
                rel='noreferrer noopener'>
                <strong>877-974-7462</strong>
              </a> Ext. 315
            </li>
          </ul>
          <p>{t('INCLUDE_YOUR')} <strong>{t('STUDENT_ID')}</strong> {t('IN_ALL_YOUR_MESSAGES')}</p>
        </>
      )}
    </div>
  );
}
