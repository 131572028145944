import React, { useState, useContext, useEffect } from "react";
import { css } from "@emotion/core";
import { useTranslation } from "react-i18next";
import useAxios from "axios-hooks";
import { WizardContext } from "../../context/WizardContext";
import { alphanumeric } from "../../utils/utils";
import cardsExample from "../../images/card-examples.png"
import Spinner from '../Spinner/index';
import Preloader from '../../components/Preloader/index';

const StepOne = () => {
  const { t } = useTranslation();
  const [noPayerID, setNoPayerID] = useState(false);
  const [noInsurance, setNoInsurance] = useState(false);
  // const [insurance, setInsurance] = useState({ name: "", value: "" });
  const [openModal, setOpenModal] = useState(false);
  const [displayProviderName, setDisplayProviderName] = useState(false);
  const [providerName, setProviderName] = useState('');

  const defaultInsurance = { name: "", value: "" };

  const { data, setData } = useContext(WizardContext);
  const [provider, getProvider] = useAxios(
    {
      url: "providers/payerId",
      method: "GET",
      headers: {
        "Authorization": `Bearer ${window.localStorage.getItem("jwt")}`,
        "X-SIS-SLUG": `${window.localStorage.getItem("slug")}`,
      }
    },
    {
      manual: true
    }
  );
  const [{ data: insuranceResponse, loading: insuranceLoading }, getInsurance] = useAxios({
    url: "providers",
    method: "GET",
    headers: {
      "Authorization": `Bearer ${window.localStorage.getItem("jwt")}`,
      "X-SIS-SLUG": `${window.localStorage.getItem("slug")}`,
    }
  },
    { manual: true });

  useEffect(() => {
    getInsurance()
  }, []);

  const checkProvider = () => {
    if (data.stepOne.payerId) {
      getProvider({
        params: {
          payerId: data.stepOne.payerId
        }
      });
    }
  }

  useEffect(() => {
    if (provider.data && !provider.error && !provider.loading) {
      setDisplayProviderName(true);
      setProviderName(provider.data.name)
    }

  }, [provider]);

  useEffect(() => {
    if (data) {
      if (data.stepOne.payerId) {
        if (data.stepOne.insurance && data.stepOne.insurance.name !== '') {
          setProviderName(data.stepOne.insurance.name)
          setDisplayProviderName(true);
        } else {
          setProviderName('')
          setDisplayProviderName(false);
        }
      } else if (data.stepOne.insurance.id) {
        setNoPayerID(true);
      } else if (data.stepOne.insurance.name) {
        setNoPayerID(true);
        setNoInsurance(true);
      }
    }

  }, [data]);

  useEffect(() => {
    setData(d => ({
      ...d,
      valid:
        !!d.stepOne.insurance.name ||
        (!!provider.data && (!provider.error || !provider.loading)),
      stepOne: {
        ...d.stepOne,
        insurance: provider.data ? provider.data : d.stepOne.insurance,
      }
    }));

    setOpenModal(!!provider.error);

  }, [provider.data, provider.error]);

  const handlePayerIDChange = (value) => {
    setData(d => ({
      ...d,
      stepOne: {
        ...d.stepOne,
        payerId: alphanumeric(value),
        insurance: defaultInsurance,
      },
      valid: false,
    }))
    if (providerName) {
      setProviderName('');
    }
    provider.data = undefined
    provider.error = undefined
    if (displayProviderName) {
      setDisplayProviderName(false);
    }
  }

  const handleInsuranceChange = (insurance) => {
    setData(d => ({
      ...d,
      valid: !!insurance.name,
      stepOne: {
        ...d.stepOne,
        insurance: insurance,
      }
    }));
  }

  const handleSelectInsuranceChange = (data) => {
    setData(d => ({
      ...d,
      valid: !!data.insurance.name,
      stepOne: {
        ...d.stepOne,
        ...data
      }
    }));
  }


  const handleNoPayerId = () => {
    setNoPayerID(true)
    setData(d => ({
      ...d,
      stepOne: {
        ...d.stepOne,
        payerId: '',
      }
    }));
  }

  return (
    <>
      {openModal && (
        <div
          css={css`
            position: fixed;
            background-color: #00000066;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
          `}
        >
          <div
            className="card card-shadowed mx-auto"
            css={css`
              width: 400px;
              max-width: 95vw;
              margin-top: 20%;
            `}
          >
            <div className="card-header">
              <span>
                <strong>{t("PAYER_ID")} / EDI #</strong> {t("NOT_VALID")}
              </span>
            </div>
            <div className="card-body">
              {t('WAIVER_STEP_ONE_NOT_RECONGNIZED')}

              <div className="d-flex justify-content-between align-items-center mt-3">
                <button
                  css={css`
                    color: #4d5259;
                    font-size: 13px;
                    background-color: #e4e7ea;
                  `}
                  className="btn border-0 px-2 py-1 bg-muted"
                  onClick={() => {
                    provider.error = null;
                    setOpenModal(false);
                  }}
                >
                  {t("RE_ENTER_ID")}
                </button>
                <button
                  css={css`
                    color: #4d5259;
                    font-size: 13px;
                    background-color: #e4e7ea;
                  `}
                  className="btn border-0 px-2 py-1 bg-muted"
                  onClick={() => {
                    provider.error = null;
                    handleNoPayerId();
                    setOpenModal(false);
                  }}
                >
                  {t("CHOOSE_INSURANCE_PROVIDER")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-6 mb-5">
          <p className="p-body mb-5 mb-md-0">
            {t("WAIVER_STEP_ONE_DESCRIPTION")}
          </p>
          <div className="payer-id form-type-material">
            {!noPayerID && (
              <>
                <div className="row align-items-end mb-3">
                  <div className="col-md-7">
                    <div className={`form-group mb-0 ${data.stepOne.payerId ? 'do-float' : ''}`}>
                      <input
                        type="text"
                        className="form-control"
                        id="payerId"
                        name="payerId"
                        value={data.stepOne.payerId}
                        onChange={e => handlePayerIDChange(e.target.value)}
                        disabled={provider.loading}
                        aria-disabled={provider.loading}
                      />
                      <label htmlFor="payerId">{t("PAYER_ID")} / EDI #</label>
                    </div>
                  </div>
                  <div className="col-md-3 text-right mt-4 mt-md-0">
                    <button
                      className={`btn btn-label btn-warning btn-bold text-white`}
                      disabled={displayProviderName}
                      onClick={checkProvider}
                    >
                      <label>
                        <i className="fa fa-check"></i>
                      </label>
                      {t("VERIFY")}
                    </button>
                  </div>
                </div>
                {provider.loading && <Preloader className="d-flex bg-transparent" />}
                {!displayProviderName ? (
                  <>
                    <div className="mb-3 text-center d-none d-md-block">
                      <label className="mb-0">{t("OR")}</label>
                    </div>

                    <div className="text-right text-md-center mt-40 mt-md-0">
                      <button
                        className="btn btn-label btn-info btn-bold"
                        onClick={handleNoPayerId}
                        css={css`
                  padding: 5px 7px;

                  @media (min-width: 420px) {
                    padding: 5px 7px 5px 45px;
                  }
                `}
                      >
                        <label
                          css={css`
                    display: none;

                    @media (min-width: 420px) {
                      display: block;
                    }
                  `}
                        >
                          <i className="fa fa-credit-card"></i>
                        </label>
                        {t("NO_PAYER_ID")}
                      </button>
                    </div>
                  </>
                )
                  : (
                    <p className="fs-17"><strong>{data.stepOne.insurance.name}</strong></p>
                  )}
              </>
            )}
            {noPayerID && !noInsurance && (
              <>
                <div className="form-group">
                  {insuranceLoading
                    ? <Spinner minHeight={false} />
                    : (<select
                      name="insurance"
                      id="insurance"
                      className="form-control"
                      data-provide="selectpicker"
                      data-live-search="true"
                      value={data.stepOne.insurance.value}
                      onChange={e => {
                        const selectedOption = e.target.querySelector(
                          `#${e.target.value}`
                        );
                        const { id, name, defaultPr, doNotAllowValidation, payerId, overridePayerId } = selectedOption.dataset;

                        handleSelectInsuranceChange({
                          payerId,
                          insurance: {
                            id,
                            name,
                            value: e.target.value,
                            default_pr: defaultPr === 'true' ? true : false,
                            do_not_allow_validation: doNotAllowValidation === 'true' ? true : false,
                            override_payer_id: overridePayerId
                          }
                        })
                      }}
                    >
                      <option value="none">{t("SELECT_INSURANCE_COMPANY")}</option>
                      {insuranceResponse && insuranceResponse.map((insurance, i) => (
                        <option
                          value={`insurance${i}`}
                          id={`insurance${i}`}
                          data-id={insurance.id}
                          data-payer-id={insurance.payer_id}
                          data-name={insurance.name}
                          data-default-pr={insurance.default_pr}
                          data-do-not-allow-validation={insurance.do_not_allow_validation}
                          data-override-payer-id={insurance.override_payer_id}
                          key={insurance.id}
                        >
                          {insurance.name}
                        </option>
                      ))}
                    </select>)}

                </div>
                <button
                  className="btn btn-label btn-warning btn-bold text-capitalize"
                  onClick={() => {
                    setData(d => ({
                      ...d,
                      valid: false,
                      stepOne: {
                        ...d.stepOne,
                        insurance: { name: '', default_pr: false }
                      }
                    }))
                    setNoInsurance(true);
                  }}
                  css={css`
                  padding: 5px 7px;
                  margin: 0 auto;
                  display: block;

                  @media (min-width: 420px) {
                    padding: 5px 7px 5px 45px;
                  }
                `}
                >
                  <label
                    css={css`
                    display: none;

                    @media (min-width: 420px) {
                      display: block;
                    }
                  `}
                  >
                    <i className="fa fa-credit-card"></i>
                  </label>
                  {t("CANT_FIND_INSURANCE_PROVIDER")}
                </button>
              </>
            )}
            {noPayerID && noInsurance && (
              <div className={`form-group mt-3 ${data.stepOne.insurance.name ? 'do-float' : ''}`}>
                <input
                  type="text"
                  className="form-control"
                  id="insurance"
                  name="insurance"
                  value={data.stepOne.insurance.name}
                  onChange={e => handleInsuranceChange({ name: e.target.value })}
                />
                <label htmlFor="insurance">
                  {t("ENTER_INSURANCE_NAME_HERE")}
                </label>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-6 d-none d-md-block">
          <img src={cardsExample} alt="Card exapmle" />
        </div>
      </div>
    </>
  );
};

export default StepOne;
