export function alphanumeric(word) {
  return word.replace(/[^\w]/g, '')
}

export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

// Getting date as MMDDYYYY without separation
export const formatDateToAPI = (date) => {
  return `${date.slice(4, 8)}-${date.slice(0, 2)}-${date.slice(2, 4)}`
}