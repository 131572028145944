import useAxios from "axios-hooks";

export function useAuthorizedAxios({ url, manual, method }) {
  const axiosHook = useAxios(
    {
      url,
      method,
      headers: {
        "Authorization" : `Bearer ${window.localStorage.getItem("jwt")}`,
        "X-SIS-SLUG" : `${window.localStorage.getItem("slug")}`,
      }
    },
    { manual }
  );
  return axiosHook;
}

export function useLogout() {
  return useAuthorizedAxios({
    url: "auth/logout",
    method: "POST",
    manual: true
  });
}
