import React, { useContext, useEffect, useState, useCallback } from "react";
import { css } from "@emotion/core";
import { WizardContext } from "../../context/WizardContext";
import ImageInput from "../ImageInput";
import { useTranslation } from "react-i18next";
import Preloader from '../Preloader/index';
import useSendFile from '../../hooks/use-sendfile';
import Spinner from '../Spinner/index';

const StepThree = () => {
  const { t } = useTranslation();
  const { data, setData } = useContext(WizardContext);
  const [frontImageMetadata, setFrontImageMetadata] = useState();
  const [backImageMetadata, setBackImageMetadata] = useState();
  const [frontUploadError, setFrontUploadError] = useState(false);
  const [backUploadError, setBackUploadError] = useState(false);
  const [{ loading: loadingFront }, executePostFront] = useSendFile();
  const [{ loading: loadingBack }, executePostBack] = useSendFile();


  const uploadFrontImage = useCallback((image) => {
    if (!!image) {
      let frontImageData = new FormData();
      frontImageData.append("file", image);
      frontImageData.append("type", "CARD_FRONT");

      executePostFront({ data: frontImageData })
        .then(({ data }) => { frontUploadError && setFrontUploadError(false); setFrontImageMetadata(data) })
        .catch(err => { setFrontUploadError(true); });
    }
  }, [frontUploadError])

  const uploadBackImage = useCallback((image) => {
    if (!!image) {
      let backImageData = new FormData();
      backImageData.append("file", image);
      backImageData.append("type", "CARD_BACK");


      executePostBack({ data: backImageData })
        .then(({ data }) => { backUploadError && setBackUploadError(false); setBackImageMetadata(data) })
        .catch(err => { setBackUploadError(true) });
    }
  }, [backUploadError])


  useEffect(() => {
    setData({
      ...data,
      stepThree: {
        imageFront: frontImageMetadata,
        imageBack: backImageMetadata
      }
    });
    //eslint-disable-next-line
  }, [frontImageMetadata, backImageMetadata]);

  return (
    <div>
      <p className="p-body mb-4 mb-md-3">{t("WAIVER_STEP_3")}</p>
      <div className="d-flex flex-wrap">
        <div className="d-flex flex-column flex-grow-1 mr-md-2">
          <p className="text-md-center mb-4 mb-md-3 fw-500">{t("FRONT_OF_CARD")}</p>
          {loadingFront && <Spinner minHeight={false} />}
          <div className={`${loadingFront ? 'd-none' : ''}`}>
            <ImageInput
              id="medical-insurance-front"
              onImageChange={image => uploadFrontImage(image)}
            />
          </div>
          {frontUploadError && (
            <div className="row justify-content-center mt-3">
              <div
                css={css`
            max-width: 250px
          `}
                className="callout callout-danger card-shadowed">
                <p className="mb-2">{t('UPLOAD_IMAGE_ERROR')}</p>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex flex-column flex-grow-1 ml-md-2">
          <p className="text-md-center mb-4 mb-md-3 fw-500">{t("BACK_OF_CARD")}</p>
          {loadingBack && <Spinner minHeight={false} />}
          <div className={`${loadingBack ? 'd-none' : ''}`}>
            <ImageInput
              id="medical-insurance-back"
              onImageChange={image => uploadBackImage(image)}
            />
          </div>
          {backUploadError && (
            <div className="row justify-content-center mt-3">
              <div
                css={css`
            max-width: 250px
            `}
                className="callout callout-danger card-shadowed">
                <p className="mb-2">{t('UPLOAD_IMAGE_ERROR')}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StepThree;
