import React, { useState, useContext, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { navigate } from "@reach/router";
import ReactGA from 'react-ga';

import StepOne from "../components/Supplemental/StepOne";
import StepTwo from "../components/Supplemental/StepTwo";
import { SchoolStateContext } from "../context/SchoolContext";
import { UserContext } from "../context/UserContext";
import { useAuthorizedAxios } from "../hooks/use-axios";
import Layout from "../components/Layout";
import Preloader from '../components/Preloader/index';
import { logout } from "../services/AuthService";
import Container from '../components/Container/index';
import CustomWizard from "../components/CustomWizard";
import SubmitErrorModal from '../components/SubmitErrorModal/index';

const Supplemental = () => {
  const { t } = useTranslation();
  const { school } = useContext(SchoolStateContext);
  const [, setUser] = useContext(UserContext);
  const [loading, setLoading] = useState(true)
  const [errorModal, setErrorModal] = useState(false);
  const [data, setData] = useState({
    step: 0,
    valid: true,
    acknowledge: false,
    lastStep: true,
  });
  const [{ error, data: dataPost, loading: loadingPost }, executePost] = useAuthorizedAxios(
    {
      url: "supplements",
      method: "POST",
      manual: true
    },
  );


  useEffect(() => {
    ReactGA.pageview('/supplemental')
  }, [])

  useEffect(() => {
    if (school && school.settings_supplement_plan) {
      setLoading(false);
    } else if (school) {
      navigate(`/${school.url_slug}/`)
    }
  }, [school]);

  useEffect(() => {
    if (dataPost && !error && !loadingPost) {
      setData(d => ({ ...d, step: d.step + 1 }))
    } else if (error && !loadingPost) {
      setErrorModal(true);
    }
  }, [dataPost, error, loadingPost]);

  const submitSupplemental = useCallback(() => {
    executePost()
    ReactGA.event({
      category: 'User',
      action: 'Press Enroll in Supplement Plan'
    });
  }, [])

  const goHome = useCallback(() => {
    navigate(`/${school.url_slug}`);
  }, [school])

  const handleFinish = useCallback(() => {
    logout(school.url_slug, setUser, school.method === 'sso' ? school.auth_config.logout : null)
  }, [school, setUser])

  const SupplementalItems = useMemo(() => [
    {
      title: "1",
      id: "supplemental-first-step",
      content: <StepOne />,
      backButton: (
        <button
          className='btn btn-secondary'
          onClick={goHome}
        >
          {t('BACK')}
        </button>
      ),
      nextButton: (
        <button
          className='btn btn-primary btn-label'
          onClick={submitSupplemental}
          disabled={!data || !data.acknowledge}
        >
          <label>
            <i className='ti-check'></i>
          </label>
          {t('SUBMIT_SUPPLEMENT_ENROLLMENT')}
        </button>
      ),
    },
    {
      title: "2",
      id: "supplemental-second-step",
      content: <StepTwo />,
      nextButton: (
        <button
          className='btn btn-secondary'
          onClick={handleFinish}
        >
          {t('LOG_OUT')}
        </button>
      ),
    },
  ], [data, goHome, handleFinish, submitSupplemental, t]);



  if (loading) {
    return <Preloader className="d-flex" />
  }


  return (
    <Layout hideLogoInMobile>
      <Container>
        <SubmitErrorModal
          display={errorModal}
          setDisplay={setErrorModal}
          title={t('SUPPLEMENTAL_SUBMISSION_NOTIFICATION')}
          description={t('SUPPLEMENTAL_SUBMISSION_ERROR')}
        />
        {loadingPost && <Preloader className="d-flex bg-transparent" />}
        <CustomWizard
          title={t('SUPPLEMENT_PLAN')}
          items={SupplementalItems}
          context={[data, setData]}
          displaySteps={false}
        />
      </Container>
    </Layout>
  );
};

export default Supplemental;
