import React from "react";
import { css } from "@emotion/core";
import ada from "../../images/ada_badge_white.svg";
import { useTranslation } from "react-i18next";
import logo from '../../images/AHP-wh.png';

const Footer = ({ hideFooterResponsive = true }) => {
  const { t } = useTranslation();
  return (
    <footer
      css={css`
          box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.49);
        `}
      className={`${hideFooterResponsive ? 'd-none d-md-flex' : 'd-md-flex'} m-0 bg-primary py-5 py-md-0 pt-md-2`}
      style={{ maxWidth: "100%" }}
    >
      <div className="col-12 col-md-4 pt-2 justify-content-start pl-5 d-none d-md-flex">
        <img width="124" height="77" src={logo} alt="logo" />
      </div>
      <div
        css={css`
          @media (max-width: 768px) {
            heigth: 260px;
          }
        `}
        className="col-12 bg-primary col-md-4 pt-md-35 pb-35">
        <svg
          css={css`
            width: 140px;
            margin: 15px auto;
            display: block;
          `}
          xmlns="http://www.w3.org/2000/svg" width="135" height="39" viewBox="0 0 135 39">
          <g fill="none" fillRule="evenodd" opacity=".7">
            <g fill="#FFF" fillRule="nonzero">
              <path d="M0.143 0.2L14.237 0.2 14.237 3.971 4.297 3.971 4.297 8.743 10.37 8.743 10.37 11.943 4.297 11.943 4.297 18.114 14.266 18.114 14.266 21.857 0.172 21.857 0.172 0.2z" transform="translate(1 1) translate(12 7)" />
              <path d="M14.122 8.714L19.508 8.714 19.565 11.914 14.122 11.914zM20.167.2L24.12.2 26.984 12.571 29.878.2 34.604.2 37.497 12.686 40.333.2 44.315.2 39.331 21.829 36.094 21.829 32.284 5.314 28.417 21.829 25.18 21.829zM50.846.2h4.383l6.847 21.629h-4.068l-1.604-5.086h-6.732l-1.604 5.086H44L50.846.2zm2.206 5.829l-2.406 7.542h4.784L53.052 6.03zM65.943.2L70.068.2 70.068 21.829 65.943 21.829zM74.938.2L78.977.2 83.359 15.057 87.799.2 91.753.2 85.365 21.8 81.354 21.8zM95.792.2L109.885.2 109.885 3.971 99.917 3.971 99.917 8.743 105.99 8.743 105.99 11.943 99.917 11.943 99.917 18.114 109.885 18.114 109.885 21.857 95.792 21.857 95.792.2z" transform="translate(1 1) translate(12 7)" />
            </g>
            <path stroke="#FFF" strokeWidth="2" d="M129.172 37H3.828C1.727 37 0 35.292 0 33.15V3.85C0 1.738 1.698 0 3.828 0h125.344C131.273 0 133 1.708 133 3.85v29.3c0 2.113-1.698 3.85-3.828 3.85z" transform="translate(1 1)" />
          </g>
        </svg>
        <p className="fs-11 text-center bold text-white m-0">
          <strong>16201 West 95th Street, Ste. 210 Lenexa, KS 66219</strong>
        </p>
        <p className="fs-11 text-center bold text-white m-0">
          <strong>
            {t('PHONE')}: 877-974-7462 Ext. 315
          </strong>
        </p>
        <p className="m-0 text-center">
          <a
            href="mailto:student-insurance@ahpcare.com"
            className="fs-11 bold text-white "
            target="_blank"
            rel='noreferrer noopener'>
            <strong>student-insurance@ahpcare.com</strong>
          </a>
        </p>
      </div>
      <div
        css={css`
          @media (max-width: 768px) {
            heigth: 260px;
          }
        `}
        className="col-12 bg-primary pb-3 col-md-4 text-center">
        <a
          href="https://onlineada.com/certified/sis-inc.biz/2113/"
          target="_blank"
          rel='noreferrer noopener'>
          <img
            css={css`
            width: 100px;
          `}
            src={ada}
            alt="ADA Badge."
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
