import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/core";
import { navigate } from "@reach/router";
import moment from 'moment';
import ReactGA from 'react-ga';
import useAxios from 'axios-hooks';

import { SchoolStateContext } from "../context/SchoolContext";
import { UserContext } from "../context/UserContext";
import Layout from '../components/Layout/index';
import CancelModal from "../components/CancelModal";
import Preloader from '../components/Preloader/index';
import CardHeader from "../components/CardHeader";
import SupplementalAddonCard from "../components/SupplementalAddonCard";
import HelpComponent from '../components/HelpComponent/index';

const Home = () => {
  const { t } = useTranslation();
  const school = useContext(SchoolStateContext);
  const [user, setUser] = useContext(UserContext);
  const [openCancelModal, setOpenCancelModal] = useState(false)

  const [{ data: me, error, loading }, getMe] = useAxios(
    {
      url: "auth/me",
      headers: {
        "Authorization": `Bearer ${window.localStorage.getItem('jwt')}`,
        "X-SIS-SLUG": `${window.localStorage.getItem("slug")}`,
      },
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    getMe();
    ReactGA.pageview('/')
  }, [])

  useEffect(() => {
    if (me && !error) {
      setUser({ ...me.student, submission: me.submission });
      window.localStorage.setItem(
        "me",
        JSON.stringify({ ...me.student, submission: me.submission })
      );
    }
  }, [me, error, school, setUser]);

  const handleCancelAndEnroll = () => {
    navigate(
      `/${school.school.url_slug}/${
      user.submission.submission_type === "SHIP Enrollment"
        ? "waiver"
        : "ship"
      }`
    );
  };

  const renderStatusCard = (status) => {
    let bgColor = 'bg-white';
    switch (status) {

      case 'PR':
      case 'PA':
      case 'PE':
      case 'NE':
        bgColor = 'bg-warning';
        break;
      case 'DE':
      case 'DF':
        bgColor = 'bg-info';
        break;
      case 'CN':
      case 'CE':
      case 'CW':
        bgColor = 'bg-danger';
        break;
      case 'AP':
      case 'OI':
        bgColor = 'bg-success';
        break;
      case 'VOLUNTARY':
        bgColor = 'bg-white';
        break;
      case 'SUPPLEMENTS':
        bgColor = 'bg-dark-gray';
        break;
      case 'NR':
        bgColor = 'bg-purple';
        break;
      default:
        break;

    }

    return (
      <div
        css={css`
          height: 150px;
          width: 150px;
          text-align: center;
          position: relative;
          @media (min-width: 768px) {
            margin: 0 20px 0 0;
          }
        `}
        className={`${bgColor}`}
      >
        <h1
          css={css`
            font-size: 75px;
            font-weight: 900;
          `}
          className="text-white"
        >
          {status}
        </h1>
        <p
          css={css`
          position: absolute;
          bottom: 10px;
          right: 0;
          `}
          className="fs-17 text-white w-100 px-4 m-0">
          {t(`CODE_${status}`)}
        </p>
      </div>
    )
  }

  const renderSubmissionDescription = (type, status) => {
    let description = '';
    if (type === "SHIP Enrollment") {
      switch (status) {
        case 'DF':
        case 'OI':
          description = `${t(`ENROLLMENTCODES_STATUS_CODES_${status}_DESCRIPTION_P1`)} ${school.school.school_insurance_name} ${t(`ENROLLMENTCODES_STATUS_CODES_${status}_DESCRIPTION_P2`)} ${school.school.school_name} ${t(`ENROLLMENTCODES_STATUS_CODES_${status}_DESCRIPTION_P3`)}`
          break;
        default:
          description = ''
          break;
      }
    } else {
      switch (status) {

        case 'PR':
        case 'PA':
          description = (
            <>
              {t(`WAIVERCODES_STATUS_CODES_${status}_DESCRIPTION_P1`)} <a
                href="mailto:student-insurance@ahpcare.com"
                className="text-nowrap"
                target="_blank"
                rel='noreferrer noopener'>
                student-insurance@ahpcare.com
              </a> {t(`WAIVERCODES_STATUS_CODES_${status}_DESCRIPTION_P2`)} <a className="text-nowrap" href="tel:877-974-7462" target="_blank" rel='noreferrer noopener'>
                877-974-7462
            </a>{t(`WAIVERCODES_STATUS_CODES_${status}_DESCRIPTION_P3`)}
            </>)

          break;

        default:
          description = t(
            `${type === "SHIP Enrollment"
              ? "ENROLLMENT"
              : "WAIVER"
            }CODES_STATUS_CODES_${status}_DESCRIPTION`)
          break;
      }
    }

    return description
  }

  if (loading) {
    return <Preloader className="d-flex" />
  }

  return (
    <Layout hideLogoInMobile>
      {school && school.school && (
        <>
          {school && school.school && school.school.settings_supplement_plan && (
            <CancelModal
              display={openCancelModal}
              setDisplay={setOpenCancelModal}
              onCloseModal={() => window.location.reload()}
            />
          )}
          <div className={`${!user || !user.submission ? 'bg-white px-4 pb-2' : ''}  px-md-5 py-md-5`}>
            <div
              css={css`
                @media (min-width: 769px) {
                  padding-left: 100px;
                  padding-right: 100px;
                }
                @media (min-width: 992px) {
                  padding-left: 15%;
                  padding-right: 15%;
                }
              `}
              className="container-md">
              {!user || !user.submission ? (
                <>
                  <h1
                    css={css`
                background-color: #3d4554;
                color: #ffffff;
                margin: 0 -22px 0px;
                padding: 13px 15px;
                font-size: 16px;

                @media (min-width: 540px) {
                  display: none;
                }
              `}
                  >
                    <strong>{t("STUDENT_HEALTH_INSURANCE")}</strong>
                  </h1>
                  <p css={css`
                      line-height: 1.42;
                      padding-top: 20px;
                      font-size: 14px;
                      @media (min-width: 540px) {
                        font-size: 30px;
                        line-height: 1.2;
                        letter-spacing: 0.2px;
                      }`}
                    className="text-muted fw-500">
                    {t('WELCOME_MSG_P1')} {school && school.school ? school.school.school_insurance_name : ''} {t('WELCOME_MSG_P2')}

                  </p>
                  <p
                    css={css`
                @media (min-width: 540px) {
                  font-size: 20px;
                }
              `}
                    className="text-muted pt-md-2 fw-400">
                    {t('WELCOME_MSG_P3')} {school && school.enrollment_segment ? school.enrollment_segment.segment_label : ''} {t('SEMESTER')} {t('WELCOME_MSG_P4')} {school && school.period ? school.period.academic_year : ''} {t('WELCOME_MSG_P5')}
                  </p>
                  <h2
                    css={css`
                font-size: 26px;
                @media (min-width: 540px) {
                  font-size: 30px;
                }
              `}
                    className="text-muted mt-3 mb-5 fw-400">
                    {t("DO_YOU_HAVE_INSURANCE")}
                  </h2>
                  <div className="d-flex flex-column flex-md-row justify-content-between">
                    <div
                      css={css`
                  :focus,
                  :focus-within {
                    background-color: #e2e2e2;
                  }
                  height: 215px;
                  @media (min-width: 769px) {
                  width: 50%;
                }
                `}
                      className="card card-shadowed mr-md-3"
                    >
                      <button
                        onClick={() =>
                          navigate(`/${school.school.url_slug}/waiver`)
                        }
                        className="btn border-0 h-100"
                        css={css`
                        padding: 15px 20px;
                        background-color: #f5f6fa
                    `}
                      >
                        <div className='h-100'>
                          <div className="d-flex align-items-center">
                            <i
                              css={css`
                          background-color: #4a90e2;
                          width: 60px;
                          height: 60px;
                          border-radius: 4px;

                          &::before {
                            position: relative;
                            top: -4px;
                          }
                        `}
                              className="fs-60 fa fa-plus text-white"
                            ></i>
                            <h1 className="text-muted fs-60 mb-0 ml-3">
                              <strong>{t("YES")}</strong>
                            </h1>
                          </div>
                          <p
                            css={css`letter-spacing: 0.5px`}
                            className="text-left text-muted">
                            {t('YES_MSG_P1')} {school && school.school && school.school.school_name} {t('YES_MSG_P2')}
                          </p>
                        </div>
                      </button>
                    </div>
                    <div
                      css={css`
                  :focus,
                  :focus-within {
                    background-color: #e2e2e2;
                  }
                  height: 215px;
                  @media (min-width: 769px) {
                  width: 50%;
                }
                `}
                      className="card card-shadowed ml-md-3"
                    >
                      <button
                        className="btn border-0 h-100 "
                        onClick={() => navigate(`/${school.school.url_slug}/ship`)}
                        css={css`
                        padding: 15px 20px;
                        background-color: #f5f6fa
                    `}
                      >
                        <div className='h-100'>
                          <div className="d-flex align-items-center">
                            <i
                              className="fs-60 fa fa-close text-white"
                              css={css`
                          background-color: #4a90e2;
                          width: 60px;
                          height: 60px;
                          border-radius: 4px;

                          &::before {
                            position: relative;
                            top: -8px;
                          }
                        `}
                            ></i>
                            <h1 className="text-muted fs-60 mb-0 ml-3">
                              <strong>{t("NO")}</strong>
                            </h1>
                          </div>
                          <p
                            css={css`letter-spacing: 0.5px`}
                            className="text-left text-muted">
                            {t('NO_MSG_P1')} {school && school.school && school.school.school_insurance_name ? school.school.school_insurance_name : school.school.school_name}  {t('NO_MSG_P2')}
                          </p>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mb-4 mt-md-4">
                    <a
                      href={school && school.school.ship_requirement_url}
                      target="_blank"
                      rel='noreferrer noopener'
                      className="btn"
                      css={css`
                      background: none;
                      border: 1px solid #4a90e2;
                      border-radius: 1px;
                      color:#4a90e2 !important;
                      font-size: 13;
                      font-weight: 400;
                    `}
                    >
                      {t('CLICK_TO_READ_MORE_P1')} {school && school.school.school_name} {t('CLICK_TO_READ_MORE_P2')}
                    </a>
                  </div>
                </>
              ) : (
                  <>
                    <div
                      className="card mb-5 mt-md-5 card-shadowed"
                      css={css`
                max-width: 720px;
                margin: 0 auto;
              `}
                    >
                      <CardHeader
                        title={user.submission && user.submission.submission_type !== "SHIP Enrollment" && t("WAIVER_APPLICATION_STATUS")}
                        bold={user.submission && user.submission.submission_type === "SHIP Enrollment" && t("SHIP_ENROLLMENT")}
                        rightComponent={<h5 css={css`@media (max-width: 768px) {color: #fff;}`} className="m-0">{user.student_id}</h5>}
                      />
                      <div className="card-body row">
                        <div className="col-12 col-md-4 mb-3 mb-md-0">
                          <p className="d-md-none">
                            {user.first_name} {user.last_name}
                          </p>
                          {/* <span className="d-md-none">{user.student_id}</span> */}
                          {user.submission && renderStatusCard(user.submission.status)}
                        </div>
                        <div className="col-12 col-md-8">
                          <div className="mb-2">
                            <p className="m-0 text-left text-md-right fw-500">
                              {t("SUBMITTED")}:{" "}
                              {user.submission && moment(user.submission.created_at).format('MM/DD/YYYY, hh:mm A')}
                            </p>
                            <p className="m-0 text-left text-md-right fw-500">
                              {t("UPDATED")}:{" "}
                              {user.submission && moment(user.submission.updated_at).format('MM/DD/YYYY, hh:mm A')}
                            </p>
                          </div>
                          <p>
                            {renderSubmissionDescription(user.submission.submission_type, user.submission.status)}
                          </p>
                        </div>
                      </div>
                      {/* SUPPLEMENT */}
                      <SupplementalAddonCard submission={user.submission} school={school.school} setOpenCancelModal={setOpenCancelModal} />
                      {/* ! SUPPLEMENT */}
                      {/* UPDATE WAIVER */}
                      {user.submission.submission_type !== "SHIP Enrollment" &&
                        user.submission.status === 'PE' && (
                          <div className="card-footer">
                            <button
                              onClick={() => navigate(`/${school.school.url_slug}/update-waiver`)}
                              className="btn d-block ml-auto btn-primary"
                            >
                              {t('UPDATE_WAIVER_APPLICATION')}
                            </button>
                          </div>
                        )}
                      {/* ! UPDATE WAIVER */}
                    </div>
                    <div
                      className="card mb-md-5 card-shadowed"
                      css={css`
                max-width: 720px;
                margin: 0 auto;
              `}
                    >
                      <CardHeader
                        bold={user.submission && user.submission.submission_type !== "SHIP Enrollment" ? t("SHIP_ENROLLMENT_STUDENT") : t("WAIVE_STUDENT")}
                      />
                      <div className="card-body">
                        <p>
                          {user.submission && user.submission.submission_type === "SHIP Enrollment"
                            ? <>
                              {
                                moment(new Date()).isBefore(moment(school.enrollment_segment.enrollment_deadline))
                              ? <> {t('CANCEL_AND_ENROLL_SHIP_P1')} {moment(school.enrollment_segment.enrollment_deadline).format('MM/DD/YYYY')}. {t('CANCEL_AND_ENROLL_SHIP_P2')} </>
                              : <> {t('CANCEL_AND_ENROLL_SHIP_P1_PASSED')}. {t('CANCEL_AND_ENROLL_SHIP_P2')} </>
                              }
                            </>
                            : <>{t('CANCEL_AND_ENROLL_WAIVER_P1')} {school && school.school.school_insurance_name ? school.school.school_insurance_name : school.school.school_name} {t('CANCEL_AND_ENROLL_WAIVER_P2')}</>
                          }
                        </p>
                      </div>
                      <div className="card-footer">
                        <button
                          onClick={handleCancelAndEnroll}
                          className="btn btn-primary d-block ml-auto"
                        >
                          {t(
                            `${
                            user.submission &&
                              user.submission.submission_type !== "SHIP Enrollment"
                              ? "ENROLL"
                              : "SUBMIT_WAIVER"
                            }`
                          )}
                        </button>
                      </div>
                    </div>
                  </>
                )}
            </div>
            <div className={`${user ||  (user && user.submission) ? 'mt-5 px-5' : ''} d-md-none`}>
              <HelpComponent />
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default Home;
