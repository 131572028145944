import React, { useContext } from 'react';
import { css } from "@emotion/core";

import { useTranslation } from "react-i18next";
import CardHeader from '../CardHeader';
import { SchoolStateContext } from '../../context/SchoolContext';
import Modal from '../Modal/index';
import { logout } from '../../services/AuthService';
import { UserContext } from "../../context/UserContext";

const SubmitErrorModal = ({ display, setDisplay, title, description }) => {
  const { t } = useTranslation();
  const { school } = useContext(SchoolStateContext)
  const [, setUser] = useContext(UserContext);

  const handleLogout = () => {
    setDisplay(false);
    logout(school.url_slug, setUser, school.method === 'sso' ? school.auth_config.logout : null)
  }

  return (
    <div
    css={css`
        .modal-content{
          border-left: solid 4px #f96868
        }
      `}
    >
      <Modal
        targetId='error-modal'
        display={display}
        setDisplay={setDisplay}>
        <CardHeader title={title} />
        <div className='card-body'>
          <p>{description}</p>
          <ul>
            <li>
              <a
                className="text-primary"
                href="mailto:student-insurance@ahpcare.com"
                target="_blank"
                rel='noreferrer noopener'>
                <strong>student-insurance@ahpcare.com</strong>
              </a>
            </li>
            <li>
              <a
                className="text-primary"
                href="tel:877-974-7462"
                target="_blank"
                rel='noreferrer noopener'>
                <strong>877-974-7462</strong>
              </a> Ext. 315
            </li>
          </ul>
          <p>{t('INCLUDE_YOUR')} <strong>{t('STUDENT_ID')}</strong> {t('IN_ALL_YOUR_MESSAGES')}</p>
          <div className='d-flex justify-content-end mt-4'>
            <button
              onClick={handleLogout}
              className='btn btn-secondary'
            >
              {t('LOG_OUT')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SubmitErrorModal
