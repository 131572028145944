import React from 'react'
import { css } from '@emotion/core';

const Container = ({ children }) => {
  return (
    <div css={css`
      @media (min-width: 769px) {
        padding: 100px 0;
      }
      @media (max-width: 768px) {
        height: 100%;
        display: flex;
      }`}
      className='container-md'>
      <div
        css={css`
          max-width: 720px;
          margin: 0 auto;
          @media (max-width: 768px) {
            display: flex;
            max-width: unset;
          }`}
        className="w-100"
      >
        {children}
      </div>
    </div>
  )
}

export default Container
