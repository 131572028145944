import React, { useEffect } from 'react';
import { css } from '@emotion/core';
import { WizardProvider } from '../../context/WizardContext'
import CardHeader from '../CardHeader/index';

const CustomWizard = ({
  items,
  title,
  context: [data, setData],
  displaySteps = true,
}) => {
  const preventCloseTab = (e) => {
    e = e || window.event;

    // For IE and Firefox prior to version 4
    if (e) {
      e.returnValue = "Applications isn't copmplete yet. Are you sure you want to exit?";
    }

    // For Safari
    return "Applications isn't copmplete yet. Are you sure you want to exit?";
  };

  useEffect(() => {
    window.onbeforeunload = preventCloseTab
  }, []);

  useEffect(() => {
    if (data.step > items.length) {
      window.onbeforeunload = null
    }
  }, [data, items])

  return (
    <WizardProvider data={data} setData={setData}>
      <div className='card mb-0 mb-md-4 h-100 w-100 bg-white'>
        <CardHeader title={title} />
        <div
          css={css`@media (max-width: 768px) {
                  padding-bottom: 60px;
                }`}
          className='card-body'>
          {displaySteps && (
            <ul className='nav nav-process nav-process-circle mb-40'>
              {items.slice(0, items.length - 1).map((item, i) => (
                <li
                  css={css`z-index:0`}
                  key={item.id}
                  className={`nav-item ${i === data.step ? 'processing' : i < data.step ? 'complete' : ''}`}
                >
                  <span className='nav-title'>{item.title}</span>
                  <a
                    className={`nav-link ${i === data.step ? 'active' : ''}`}
                    data-toggle='tab'
                  >
                    <span
                      css={css`
                        opacity: 0;
                      `}
                    >
                      {item.title}
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          )}
          <div className='tab-content'>
            {items.map((item, i) => (
              <div
                key={item.id}
                className={`tab-pane fade ${i === data.step ? 'active show' : ''
                  }`}
                id={item.id}
              >
                <div className='my-2'>{item.content}</div>
              </div>
            ))}
          </div>
        </div>
        <div
          css={css`
                @media (max-width: 768px) {
                  background-color: #f5f6fa;
                  position: fixed;
                  bottom: 0;
                  }
            `}
          className="card-footer w-100">
          <div className={`flexbox ${items[data.step].backButton ? '' : 'justify-content-end'}`}>
            {items[data.step].backButton && items[data.step].backButton}

            {items[data.step].nextButton}

          </div>
        </div>
      </div>

    </WizardProvider>
  )
}

export default CustomWizard
