import React, { useContext } from 'react'
import { SchoolStateContext } from '../../context/SchoolContext'
import { useTranslation } from 'react-i18next';

const StepThree = () => {
  const { school } = useContext(SchoolStateContext)
  const { t } = useTranslation()
  return (
    <p className="p-body">
      {t('SHIP_STEP_3_MESSAGE_P1')} {school.school_insurance_name ? school.school_insurance_name : school.school_name } {t('SHIP_STEP_3_MESSAGE_P2')} {school.school_name} {t('SHIP_STEP_3_MESSAGE_P3')}
    </p>
  )
}

export default StepThree
