import React, { createContext } from "react";

const WizardContext = createContext([{}, undefined]);
const LocalStateProvider = WizardContext.Provider;

function WizardProvider({ children, data, setData }) {
  return (
    <LocalStateProvider value={{ data, setData }}>
      {children}
    </LocalStateProvider>
  );
}

export { WizardProvider, WizardContext };
