import React from 'react';
import { useTranslation } from "react-i18next";
import { navigate } from "@reach/router";

const SupplementalAddonCard = ({ submission, school, setOpenCancelModal }) => {
  const { t } = useTranslation();

  if (
    submission.submission_type !== "SHIP Enrollment" &&
    submission.status === 'AP' &&
    school.supplement_plan_enabled &&
    school.settings_supplement_plan &&
    school.settings_supplement_plan.enrollments_enabled
  ) {
    return (
      <>
        <div className="px-4">
          <hr className="my-3" />
          <p><strong>{t('SUPPLEMENT_PLAN_ADDON')}</strong></p>
          <p>
            {submission.has_supplement
              ? (<>{t('SUPPLEMENT_PLAN_ADDON_DESCRIPTION_P1')} {school.settings_supplement_plan.plan_title} {t('SUPPLEMENT_PLAN_ADDON_DESCRIPTION_P2')} {school.health_office_services_name} {t('SUPPLEMENT_PLAN_ADDON_DESCRIPTION_P3')}</>)
              : (<>{t('SUPPLEMENT_NO_PLAN_ADDON_DESCRIPTION_P1')} {school.settings_supplement_plan.plan_title} {t('SUPPLEMENT_NO_PLAN_ADDON_DESCRIPTION_P2')} {school.health_office_services_name} {t('SUPPLEMENT_NO_PLAN_ADDON_DESCRIPTION_P3')}</>)}
          </p>
        </div>

        <div className="card-footer">
          <button
            onClick={submission.has_supplement ? () => setOpenCancelModal(true) : () => navigate(`/${school.url_slug}/supplemental`)}
            className={`btn d-block ml-auto ${submission.has_supplement ? 'btn-danger' : 'btn-primary'}`}
          >
            {submission.has_supplement ? t('CANCEL_SUPPLEMENT_PLAN') : t('ADD_SUPPLEMENT_PLAN')}
          </button>
        </div>
      </>
    )
  }
  return null
}

export default SupplementalAddonCard;



