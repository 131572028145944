import React from 'react'
import logo from '../../images/logo-dark.png'

const PreAuth = ({ children }) => {
  return (
    <div
      className='row min-h-fullscreen center-vh p-20 m-0 bg-light'
    >
      <div className='col-12'>
        <div className='logo mb-70 text-center d-block'>
          <img src={logo} alt='Logo' width="270" className="mb-10" />
          <p>
            <strong>
              Electronic Waiver Application Insurance Verification & Eligibility
              System
            </strong>
          </p>
        </div>
        <div
          className='card card-shadowed w-350px mx-auto'
          style={{ maxWidth: '100%' }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default PreAuth
