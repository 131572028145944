import { gql } from '@apollo/client';

// Queries
export const students_submissions_ship = gql`
query students_submissions_ship($studentId: uuid,$enrollment_period_id:uuid) {
  students_submissions(where: {_and: 
    {student_id: {_eq: $studentId}, 
      waiver_form_data: {_is_null: true}, 
      status: {_neq: "CE"},
      enrollment_period_id: {_eq: $enrollment_period_id}
    }
  }) {
    student_id
    status
    enrollment_period_id
    waiver_form_data
  }
}
`;

export const students_submissions_waiver = gql`
query students_submissions_ship($studentId: uuid ,$waiverForm: jsonb,$enrollment_period_id:uuid) {
  students_submissions(where: {_and: 
    {student_id: {_eq: $studentId}, 
      waiver_form_data: {_is_null: false, _contains:$waiverForm},
      enrollment_period_id: {_eq: $enrollment_period_id}
    }
  }) {
    student_id
    status
    enrollment_period_id
    waiver_form_data
  }
}
`;