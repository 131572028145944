import React, { useContext } from 'react'
import { css } from '@emotion/core'
import moment from 'moment';
import { useTranslation } from 'react-i18next'
import { SchoolStateContext } from '../../context/SchoolContext'
import FileRow from '../FileRow';

const StepOne = () => {
  const { t } = useTranslation()
  const { enrollment_segment, period } = useContext(SchoolStateContext)

  return (
    <div css={css`
        box-shadow: none;
      `} className="card">
      <div>
        <div css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            margin-bottom: 90px;
            @media (max-width: 768px) {
              margin-bottom: 24px;
            }

            span {
              font-size: 14px;
              color: #4d5259;
              font-weight: normal;
              &.field-name {
                @media(max-width: 420px) {
                  font-size: 10px;
                  color: #a8a9ae;
                }
              }
            }

          `}>
          <span className='field-name fw-500'>{t('ENROLLMENT_PERIOD')}</span>
          <span className="fw-500">{enrollment_segment.segment_label} {period.academic_year}</span>
          <span className='field-name fw-500'>{t('EFFECTIVE_AND_TERMINATION_DATES')}</span>
          <span className="fw-500">{moment(enrollment_segment.coverage_start_date).format('MM/DD/YYYY')} - {moment(enrollment_segment.coverage_end_date).format('MM/DD/YYYY')}</span>
          <span className='field-name fw-500'>{t('ENROLLMENT_DEADLINE')}</span>
          <span className="fw-500">{moment(enrollment_segment.enrollment_deadline).format('MM/DD/YYYY')}</span>
          <span className='field-name fw-500'>{t('PREMIUM')}</span>
          <span className="fw-500">${enrollment_segment.price}</span>
        </div>
        {enrollment_segment && enrollment_segment.files && enrollment_segment.files.length > 0 && (
          <div>
            <p css={css` 
            margin-bottom: 8px;
            font-size: 14px;
            color: #4d5259;
            font-weight: 500;
          `}
          className="p-body"
          >
              {t('PLAN_INFORMATION')}
            </p>
            <div css={
              css`
            @media (min-width: 768px) {
              border-radius: 5px;
              border: solid 1px #979797;
                  }
            `}
              className="p-0 p-md-2 ">
              {enrollment_segment.files.map(file => (
                <FileRow
                  key={file.id}
                  name={file.name}
                  extension={file.extension}
                  downloadLink={file.cdn_url}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default StepOne
