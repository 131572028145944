import React, { useContext, useMemo } from 'react'
import { useTranslation } from "react-i18next";
import { css } from "@emotion/core";
import { navigate } from "@reach/router";
import { SchoolStateContext } from '../../context/SchoolContext';
import { UserContext } from "../../context/UserContext";
import CardHeader from '../CardHeader/index';
import { logout } from '../../services/AuthService';

const WaiverApprovedCard = () => {
  const { t } = useTranslation();
  const { school } = useContext(SchoolStateContext);
  const [, setUser] = useContext(UserContext);

  const displayAddOn = useMemo(() => school.supplement_plan_enabled &&
    school.settings_supplement_plan &&
    school.settings_supplement_plan.enrollments_enabled, [school])


  return (
    <div className='card'>
      <CardHeader title={t('WAIVER_APPLICATION')} />
      <div className="card-body row">
        <div className="col-12 col-md-4 mb-3 mb-md-0">
          <div
            css={css`
          height: 150px;
          width: 150px;
          text-align: center;
          position: relative;
          @media (min-width: 768px) {
            margin: 0 20px 0 0;
          }
        `}
            className="bg-success"
          >
            <h1
              css={css`
            font-size: 75px;
            font-weight: 900;
          `}
              className="text-white"
            >
              AP
            </h1>
            <p
              css={css`
          position: absolute;
          bottom: 10px;
          right: 0;
          `}
              className="fs-17 text-white w-100 px-4 m-0">
              {t('CODE_AP')}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-8">
          <p className="p-body">{t('WAIVERCODES_STATUS_CODES_AP_DESCRIPTION')}</p>
        </div>
      </div>
      {displayAddOn && (
        <div>
          <div className="px-4">
            <hr className="my-3" />
            <p><strong>{t('SUPPLEMENT_PLAN_ADDON')}</strong></p>
            <p className="p-body">
              {t('SUPPLEMENT_NO_PLAN_ADDON_DESCRIPTION_P1')} {school.settings_supplement_plan.plan_title} {t('SUPPLEMENT_NO_PLAN_ADDON_DESCRIPTION_P2')} {school.health_office_services_name} {t('SUPPLEMENT_NO_PLAN_ADDON_DESCRIPTION_P3')}
          </p>
          </div>
          <div className="py-5 text-center">
            <a
              href={school && school.settings_supplement_plan && school.settings_supplement_plan.plan_url}
              target="_blank"
              rel='noreferrer noopener'
              className="btn"
              css={css`
            background: none;
            border: 1px solid #48b0f6;
            border-radius: 1px;
            color:#48b0f6 !important;
            font-size: 13;
            font-weight: bold;
          `}
            >
              {t('CLICK_TO_READ_MORE_SUPPLEMENTAL_INSURANCE_P1')} {school && school.settings_supplement_plan && school.settings_supplement_plan.plan_title} {t('CLICK_TO_READ_MORE_SUPPLEMENTAL_INSURANCE_P2')}
            </a>
          </div>
        </div>
      )}
      <div className={`card-footer d-flex ${displayAddOn ? 'justify-content-between' : 'justify-content-end'}`}>
        <button
          onClick={() => logout(school.url_slug, setUser, school.method === 'sso' ? school.auth_config.logout : null)}
          className="btn d-block btn-secondary"
        >
          {t('LOG_OUT')}
        </button>
        {displayAddOn && (
          <button
            onClick={() => navigate(`/${school.url_slug}/supplemental`)}
            className="btn d-block btn-primary"
          >
            {t('ADD_SUPPLEMENT_PLAN')}
          </button>
        )}
      </div>
    </div>
  )
}

export default WaiverApprovedCard;
