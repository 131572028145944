import React, { useContext, useCallback } from 'react'
import { css } from '@emotion/core'
import moment from 'moment';
import { useTranslation } from 'react-i18next'
import { SchoolStateContext } from '../../context/SchoolContext';
import { WizardContext } from "../../context/WizardContext";
import Checkbox from '../Checkbox/index';

const StepOne = () => {
  const { t } = useTranslation()
  const { enrollment_segment, period, school } = useContext(SchoolStateContext)
  const { data, setData } = useContext(WizardContext);

  const handleCheckBox = useCallback(() => {
    setData(d => ({
      ...d,
      acknowledge: !d.acknowledge,
      valid: !d.acknowledge
    }));
  }, [setData])

  return (
    <div css={css`
        box-shadow: none;
      `} className="card">
      <div>
        <div css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            margin-bottom: 90px;
            @media (max-width: 768px) {
              margin-bottom: 24px;
            }
            
            span {
              font-size: 14px;
              color: #4d5259;
              font-weight: normal;
              &.field-name {
                @media(max-width: 420px) {
                  font-size: 10px;
                  color: #a8a9ae;
                }
              }
            }

          `}>
          <span className='field-name fw-500'>{t('ENROLLMENT_PERIOD')}</span>
          <span className="fw-500">{enrollment_segment.segment_label} {period.academic_year}</span>
          <span className='field-name fw-500'>{t('DATES_COVERAGE')}</span>
          <span className="fw-500">{moment(enrollment_segment.coverage_start_date).format('MM/DD/YYYY')} - {moment(enrollment_segment.coverage_end_date).format('MM/DD/YYYY')}</span>
          <span className='field-name fw-500'>{t('COST_PER_SEMESTER')}</span>
          <span className="fw-500">${school.settings_supplement_plan.cost}</span>
        </div>

        <p className="p-body mt-3">
          {school.settings_supplement_plan.plan_title} {t('SUPPLEMENTAL_STEP_ONE_DESCRIPTION_P1')} {school.health_office_services_name}. {school.settings_supplement_plan.plan_title} {t('SUPPLEMENTAL_STEP_ONE_DESCRIPTION_P2')} {school.settings_supplement_plan.plan_title} {t('SUPPLEMENTAL_STEP_ONE_DESCRIPTION_P3')}
        </p>
        {enrollment_segment.sequence === 0 && (
          <p className="p-body">
            {t('SUPPLEMENTAL_STEP_ONE_DESCRIPTION_P4')} {school.settings_supplement_plan.plan_title} {t('SUPPLEMENTAL_STEP_ONE_DESCRIPTION_P5')} {school.settings_supplement_plan.plan_title} {t('SUPPLEMENTAL_STEP_ONE_DESCRIPTION_P6')}{school.settings_supplement_plan.cost} {t('SUPPLEMENTAL_STEP_ONE_DESCRIPTION_P7')} {school.settings_supplement_plan.plan_title}.
          </p>
        )}
        <div className="custom-control custom-control-lg custom-checkbox mt-5">
          <Checkbox
            checked={data.acknowledge}
            onCheck={handleCheckBox}
            label={t("I_ACKNOWLEDGE_THE_CONDITIONS_ABOVE")}
            labelStyle="fs-14 fw-500"
          />
          {/* <input
            checked={data.acknowledge}
            onInput={handleCheckBox}
            onChange={handleCheckBox}
            type="checkbox"
            className="custom-control-input"
            id="acknowledge"
          />
          <label className="custom-control-label fs-14 fw-500" htmlFor="acknowledge">
            {t("I_ACKNOWLEDGE_THE_CONDITIONS_ABOVE")}
          </label> */}
        </div>
      </div>
    </div>
  )
}

export default StepOne
