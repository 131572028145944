import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'

const ImageInput = ({ onImageChange, id, disabled }) => {
  const [image, setImage] = useState(null)
  
  useEffect(() => {
    onImageChange(image)
  }, [image])

  const isDesktop = window.matchMedia('(min-width: 420px)').matches
  return (
    <>
      <input
        type='file'
        name='image'
        id={id}
        data-provide={isDesktop ? 'dropify' : ''}
        className={isDesktop ? 'dropify' : ''}
        accept='image/png,image/gif,image/jpeg,application/pdf'
        onInput={e => setImage(e.target.files[0])}
        disabled={disabled}
        css={css`
          @media (max-width: 419px) {
            position: absolute;
            left: -10000px;
          }
        `}
      />
      <label
        css={css`
          color: #979797;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          margin-bottom: 2em;
          transition: color 0.2s linear;

          &.with-image {
            &::before {
              content: 'File Uploaded...';
              color: #282828;
            }
          }

          &::before {
            content: 'Choose file...';
          }

          i {
            width: 48px;
            height: 36px;
            border: solid 1px #eaeaea;
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.3em;
          }

          @media (min-width: 420px) {
            display: none;
          }
          @media (max-width: 768px) {
            margin-right: 55px;
            border-bottom: 1px solid #d8d8d8;
            margin-bottom: 34px;
          }
        `}
        htmlFor={id}
        className={image ? 'with-image' : ''}
      >
        <i 
        css={css`
          @media (max-width: 768px) {
            margin-right: -55px;
          }
        `}
        className='fa fa-upload'></i>
      </label>
    </>
  )
}

export default ImageInput
