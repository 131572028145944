import React, { useContext } from 'react'
import { useTranslation } from "react-i18next";
import { css } from "@emotion/core";
import { navigate } from "@reach/router";
import { SchoolStateContext } from '../../context/SchoolContext';
import { UserContext } from "../../context/UserContext";
import CardHeader from '../CardHeader/index';
import { logout } from '../../services/AuthService';

const SupplementalCard = () => {
  const { t } = useTranslation();
  const { school } = useContext(SchoolStateContext);
  const [, setUser] = useContext(UserContext);

  const handleSupplementSignUp = () => {
    navigate(`/${school.url_slug}/supplemental?supplemental=true`);
  }


  return (
    <div className='card'>
      <CardHeader title={t('SUPPLEMENT_PLAN')} />
      <div
        css={css`
        height: 350px
      `}
        className='card-body'>
        <p className="p-body">
          {t('SUPPLEMENTAL_INSURANCE_P1')} {school && school.settings_supplement_plan && school.settings_supplement_plan.plan_title} {t('SUPPLEMENTAL_INSURANCE_P2')}
          <br /><br />
          {t('SUPPLEMENTAL_INSURANCE_P3')} {school && school.settings_supplement_plan && school.settings_supplement_plan.plan_title}, {t('SUPPLEMENTAL_INSURANCE_P4')}
        </p>
        <div className="pt-3 text-center">
          <a
            href={school && school.settings_supplement_plan && school.settings_supplement_plan.plan_url}
            target="_blank"
            rel='noreferrer noopener'
            className="btn"
            css={css`
            background: none;
            border: 1px solid #48b0f6;
            border-radius: 1px;
            color:#48b0f6 !important;
            font-size: 13;
            font-weight: bold;
          `}
          >
            {t('CLICK_TO_READ_MORE_SUPPLEMENTAL_INSURANCE_P1')} {school && school.settings_supplement_plan && school.settings_supplement_plan.plan_title} {t('CLICK_TO_READ_MORE_SUPPLEMENTAL_INSURANCE_P2')}
          </a>
        </div>
      </div>
      <div
        css={css`
          @media (max-width: 768px) {
            background-color: #f5f6fa;
            position: fixed;
            bottom: 0;
            }`}
        className="card-footer pt-3 w-100">
        <div className="d-flex justify-content-between">

          <button
            onClick={() => logout(school.url_slug, setUser, school.method === 'sso' ? school.auth_config.logout : null)}
            className="btn btn-secondary d-block"
          >
            {t('LOG_OUT')}
          </button>
          <button onClick={handleSupplementSignUp} className="btn btn-secondary d-block">
            {t('SUPPLEMENT_SIGN_UP')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default SupplementalCard;
