import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from "@emotion/core";
import { WizardContext } from '../../context/WizardContext'
import { alphanumeric } from '../../utils/utils'
import cardsExample from "../../images/card-examples.png";
import CurrencyFormat from "react-currency-format";


const StepTwo = ({ loading }) => {
  const { t } = useTranslation()
  const { data, setData } = useContext(WizardContext)
  const [phoneError, setPhoneError] = useState(false)

  const handlePhoneChange = e => {
    const trimmedPhone = e.value.trim().replace(/\s+/g, '')
    if (trimmedPhone.length > 0 && trimmedPhone[0] === '1') {
      setPhoneError(true)
      setData(d => ({
        ...d,
        valid: !!d.stepTwo.memberID && trimmedPhone.length === 10,
        stepTwo: {
          ...d.stepTwo,
          phone: '',
        }
      }))
    } else {

      setPhoneError(false)
      setData(d => ({
        ...d,
        valid: !!d.stepTwo.memberID && trimmedPhone.length === 10,
        stepTwo: {
          ...d.stepTwo,
          phone: trimmedPhone,
        },
      }))
    }
  }

  const handleMemberChange = value => {
    setData(d => ({
      ...d,
      valid: !!value && d.stepTwo.phone.length === 10,
      stepTwo: {
        ...d.stepTwo,
        memberID: value
      },
    }))
  }

  const handleGroupChange = value => {
    setData(d => ({
      ...d,
      stepTwo: {
        ...d.stepTwo,
        groupNumber: value
      },
    }))
  }

  return (
    <>
      <div
        data-provide='validation'
        noValidate={true}
        className="row"
      >
        <div className="col-md-6 mb-3">
          <div className='member-id form-type-material'>
            <p className="p-body mb-4 mb-md-3">
              {t('WAIVER_STEP_TWO_DESCRIPTION_P1')} <strong>{t('MEMBER')} #</strong> {t('AND')} <strong>{t('GROUP')} #</strong> {t('WAIVER_STEP_TWO_DESCRIPTION_P2')} <strong>{t('GROUP')} #</strong>, {t('WAIVER_STEP_TWO_DESCRIPTION_P3')}
            </p>
            <p className="fs-17 mb-4 mb-md-3"><strong>{data && data.stepOne && data.stepOne.insurance.name}</strong></p>
            <div className={`form-group ${data.stepTwo.memberID ? 'do-float' : ''}`}>
              <input
                type='text'
                name='memberID'
                id='memberID'
                className='form-control'
                value={data.stepTwo.memberID}
                onChange={e => handleMemberChange(alphanumeric(e.target.value))}
                required
              />
              <label htmlFor='memberID' className="required-lg">{t('MEMBER_ID')}</label>
            </div>
          </div>
          <div className='form-type-material'>
            <div className='form-group'>
              <input
                type='text'
                name='groupID'
                id='groupID'
                className='form-control'
                value={data.stepTwo.groupNumber}
                onChange={e => handleGroupChange(alphanumeric(e.target.value))}
              />
              <label htmlFor='groupID'>{t('GROUP_NUMBER')}</label>
            </div>
            <p className="p-body mb-4 mb-md-3">
              {t('WAIVER_STEP_TWO_LOCATE_P1')} <strong>{t('CUSTOMER_SERVICE')}</strong> {t('WAIVER_STEP_TWO_LOCATE_P2')}
            </p>
            <div className='form-group do-float'>
              <label className='label-floated required-lg'>
                {t('CUSTOMER_SERVICE_PHONE')} #
              </label>
              <CurrencyFormat
                id="dateOfBirth"
                name="dateOfBirth"
                value={data.stepTwo.phone}
                onValueChange={val => handlePhoneChange(val)}
                className="form-control"
                format="+1 ###-###-####"
                placeholder="+1 000-000-0000"
                required
              />
            </div>
            <div css={css`
                height: 24px;
                margin-top: -15px;
              `}
            >
              {phoneError && <label className='text-danger'>
                {t('PHONE_NO_+1')}
              </label>
              }
            </div>
          </div>
        </div>
        <div className="col-md-6 d-none d-md-block">
          <img src={cardsExample} alt="Card exapmle" />
        </div>
      </div>
      <div className="row justify-content-center">
        {loading && (
          <>
            <p className="p-body">{t('INSURANCE_BEING_VERIFIED')}</p>
            <div>
              <div className="spinner-dots">
                <span className="dot1"></span>
                <span className="dot2"></span>
                <span className="dot3"></span>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default StepTwo
