import React, { useState, useContext, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';
import { navigate } from '@reach/router';

import { SchoolStateContext } from "../context/SchoolContext";
import StepOne from "../components/PendingWaiver/StepOne";
import StepTwo from "../components/PendingWaiver/StepTwo";
import StepThree from "../components/PendingWaiver/StepThree";
import { useAuthorizedAxios } from "../hooks/use-axios";
import { UserContext } from "../context/UserContext";
import Layout from '../components/Layout/index';
import { logout } from '../services/AuthService';
import Container from "../components/Container";
import CustomWizard from "../components/CustomWizard";
import SubmitErrorModal from '../components/SubmitErrorModal/index';
import Preloader from '../components/Preloader/index';

const emptyWaiverData = {
  step: 0,
  valid: true,
  stepOne: {
    payerId: "",
    insurance: {
      name: "",
      id: ""
    },
    memberID: "",
    groupNumber: "",
    phone: ""
  },
  stepTwo: {
    imageFront: "",
    imageBack: "",
    letter: ""
  }
};

const PendingWaiver = () => {
  const { t } = useTranslation();
  const [{ submission }, setUser] = useContext(UserContext);
  const [waiverData, setWaiverData] = useState(emptyWaiverData);
  const { school } = useContext(SchoolStateContext);
  const [errorModal, setErrorModal] = useState(false);
  const [{ response, loading, error }, postWaiver] = useAuthorizedAxios({
    url: `waivers/${submission.id}`,
    method: "POST",
    manual: true
  });

  useEffect(() => {
    ReactGA.pageview('/update-waiver')
  }, [])

  useEffect(() => {
    if (error && process.env.REACT_APP_SENTRY_ENABLE) {
      const payload = {
        memberId: waiverData.stepOne.memberID,
        memberSupportPhone: waiverData.stepOne.phone,
        groupNumber: waiverData.stepOne.groupNumber
      };

      if (waiverData.stepOne.payerId) {
        payload.payerId = waiverData.stepOne.payerId;
      }
      if (waiverData.stepOne.insurance.id) {
        payload.insuranceId = waiverData.stepOne.insurance.id;
      } else if (waiverData.stepOne.insurance.name) {
        payload.payerName = waiverData.stepOne.insurance.name;
      }
      Sentry.captureMessage(`Post /waivers/{id}
      -- Payload${JSON.stringify(payload)}
      -- Error: ${JSON.stringify(error)}`, { level: "error" })
    }
  }, [error, waiverData])

  useEffect(() => {
    if (submission && submission.waiver_form_data) {
      const {
        insuranceCompanyName,
        insuranceMemberNumber,
        insuranceGroupNumber,
        payerId,
        insuranceId,
        insurancePhoneNumber,
      } = submission.waiver_form_data;
      setWaiverData(w => ({
        ...w,
        valid: true,
        stepOne: {
          payerId,
          insurance: {
            name: insuranceCompanyName || '',
            id: insuranceId || ''
          },
          memberID: insuranceMemberNumber || '',
          groupNumber: insuranceGroupNumber || '',
          phone: insurancePhoneNumber || '',
        },
        stepTwo: {
          valid: false,
          imageFront: "",
          imageBack: "",
          letter: ""
        },
        period: `${submission.segment.segment_label} ${submission.period.academic_year}` || '',
        lastUpdated: submission.updated_at || '',
      }));
    }
  }, [submission]);

  const submitWaiver = useCallback(() => {
    const payload = {
      memberId: waiverData.stepOne.memberID,
      memberSupportPhone: waiverData.stepOne.phone,
      groupNumber: waiverData.stepOne.groupNumber
    };

    if (waiverData.stepOne.payerId) {
      payload.payerId = waiverData.stepOne.payerId;
    }
    if (waiverData.stepOne.insurance.id) {
      payload.insuranceId = waiverData.stepOne.insurance.id;
    } else if (waiverData.stepOne.insurance.name) {
      payload.payerName = waiverData.stepOne.insurance.name;
    }
    // setWaiverData({
    //   ...waiverData,
    //   step: waiverData.step + 1,
    // });
    postWaiver({
      params: payload
    });
    ReactGA.event({
      category: 'User',
      action: 'Press Update Waiver'
    });
  }, [postWaiver, waiverData])

  const handleNext = useCallback(() => {
    setWaiverData({
      ...waiverData,
      step: waiverData.step + 1,
    });
  }, [waiverData])

  useEffect(() => {
    if (response && !error && !loading) {
      setWaiverData(d => ({
        ...d,
        step: d.step + 1,
      }));
    } else if (error && !loading) {
      setErrorModal(true)
    }
  }, [response, error, loading])

  const handleFinish = useCallback(() => {
    logout(school.url_slug, setUser, school.method === 'sso' ? school.auth_config.logout : null)
  }, [school, setUser])

  const goHome = useCallback(() => {
    navigate(`/${school.url_slug}`);
  }, [school])

  const goBack = useCallback(() => {
    setWaiverData(d => ({
      ...d,
      step: d.step - 1,
    }))
  }, [])

  const waiverItems = useMemo(() => [
    {
      title: "1",
      id: "first-step",
      content: <StepOne />,
      backButton: (
        <button
          className='btn btn-secondary'
          onClick={goHome}
        >
          {t('BACK')}
        </button>
      ),
      nextButton: (
        <button
          className='btn btn-secondary'
          onClick={handleNext}
          disabled={!waiverData || (waiverData && !waiverData.valid)}
        >
          {t('NEXT')}
        </button>
      ),
    },
    {
      title: "2",
      id: "second-step",
      content: <StepTwo />,
      backButton: (
        <button
          className='btn btn-secondary'
          onClick={goBack}
        >
          {t('BACK')}
        </button>
      ),
      nextButton: (
        <button
          className='btn btn-primary btn-label'
          onClick={submitWaiver}
          disabled={!waiverData || (waiverData && !waiverData.stepTwo.valid)}
        >
          <label>
            <i className='ti-check'></i>
          </label>
          {t('SUBMIT_WAIVER_APPLICATION')}
        </button>
      ),
    },
    {
      title: "3",
      id: "third-step",
      content: <StepThree />,
      nextButton: (
        <button
          className='btn btn-secondary'
          onClick={handleFinish}
          disabled={!waiverData || (waiverData && !waiverData.valid)}
        >
          {t('LOG_OUT')}
        </button>
      ),
    },
  ], [goBack, goHome, handleFinish, handleNext, submitWaiver, t, waiverData])

  return (
    <Layout hideLogoInMobile>
      <Container>
        {loading && <Preloader className="d-flex bg-transparent" />}
        <SubmitErrorModal
          display={errorModal}
          setDisplay={setErrorModal}
          title={t('WAIVER_SUBMISSION_NOTIFICATION')}
          description={t('WAIVER_SUBMISSION_ERROR')}
        />
        {waiverData.period && (
          <CustomWizard
            title={t('WAIVER_APPLICATION')}
            items={waiverItems}
            context={[waiverData, setWaiverData]}
          />
        )}
      </Container>
    </Layout>
  );
};

export default PendingWaiver;
