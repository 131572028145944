import React from 'react'
import { Link } from '@reach/router'
import { useContext } from 'react'
import { SchoolStateContext } from '../context/SchoolContext'
import Layout from '../components/Layout';

const InternalError = () => {
  const school = useContext(SchoolStateContext)
  return (
    <Layout>
      <div className='row no-margin h-fullscreen' style={{ paddingTop: '10%' }}>
        <div className='col-12'>
          <div className='card card-transparent mx-auto text-center'>
            <h1 className='text-secondary lh-1' style={{ fontSize: '200px' }}>
              500
          </h1>
            <hr className='w-30px' />
            <h3 className='text-uppercase'>Internal Server Error</h3>

            <p className='lead'>
              Looks like we have an internal issue, please try again in couple of
              minutes.
          </p>

            <ul className='nav nav-primary nav-dotted nav-dot-separated justify-content-center fs-14'>
              <li className='nav-item'>
                <span className='nav-link'>Report problem</span>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to={`/${school.slug}`}>
                  Return home
              </Link>
              </li>
            </ul>
            <hr className='w-30px' />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default InternalError
