import React, { useEffect } from 'react'
import Preloader from '../components/Preloader/index';
import { navigate } from '@reach/router';

const SsoRedirect = () => {
  useEffect(() => {
    const parsedParam = window.location.search.replace('?','').split('&')
    const result ={}
    for (const param of parsedParam) {
      const splitedParam = param.split('=')
      result[splitedParam[0]] = splitedParam[1]
    }

    if (result.slug) {
      window.localStorage.setItem("slug", result.slug)
    }
    if (result.token) {
      window.localStorage.setItem("jwt", result.token);
    }
    navigate(`log-in${result.error ? `?error=${result.error}` : ''}`)
  }, [])
  return (
    <Preloader className="d-flex" />
  )
}

export default SsoRedirect
