import React, { useMemo, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { navigate } from "@reach/router";
import useAxios, { configure } from "axios-hooks";
import Axios from "axios";
import { useForm } from "react-hook-form";
import { useContext, useState } from "react";
import { SchoolStateContext } from "../context/SchoolContext";
import { UserContext } from "../context/UserContext";
import CurrencyFormat from "react-currency-format";
import { css } from "@emotion/core";
import DOMPurify from "dompurify";
import { parseJwt, formatDateToAPI } from "../utils/utils";

// Components
import Alert from "../components/Alert";
import LoginError from "../components/LoginError/index";
import Preloader from "../components/Preloader/index";
import Layout from "../components/Layout/index";

const Login = (props) => {
  const { t } = useTranslation();
  const school = useContext(SchoolStateContext) || {};
  const [, setUser] = useContext(UserContext);
  const [date, setDate] = useState("");
  const [studentId, setStudentId] = useState("");
  const [wrongDateError, setWrongDateError] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [mask, setMask] = useState({
    format: "############",
    mask: ["X", "X", "X", "X", "X", "X", "X", "X", "X", "X", "X", "X"],
  });
  const [lastName, setLastName] = useState();

  const { handleSubmit, formState } = useForm({
    mode: "onChange",
  });
  const [{ error, loading }, executeLogin] = useAxios(
    {
      url: "auth/demographics",
      method: "POST",
    },
    {
      manual: true,
    }
  );
  const [{ data: me, error: meError }, getMe] = useAxios(
    {
      url: "auth/me",
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const studentIdHasMask = useMemo(
    () =>
      school &&
      school.school &&
      school.school.student_id_format_mask &&
      school.school.student_id_format_mask.length &&
      school.school.student_id_format_mask.length > 0,
    [school]
  );

  useEffect(() => {
    if (studentIdHasMask) {
      let format = "";
      const mask = [];
      for (
        let index = 0;
        index < school.school.student_id_format_mask.length;
        index++
      ) {
        format = format + "#";
        mask.push("X");
      }
      setMask({
        format,
        mask,
      });
    }
  }, [school, studentIdHasMask]);

  useEffect(() => {
    if (window.localStorage.getItem("jwt")) {
      getMe({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
          "X-SIS-SLUG": `${window.localStorage.getItem("slug")}`,
        },
      });
    } else {
      setFirstLoad(false);
    }
  }, [getMe]);

  useEffect(() => {
    if (me && me.student && me.student.id && !meError) {
      window.localStorage.setItem(
        "permanent-slug",
        window.localStorage.getItem("slug")
      );
      window.localStorage.setItem(
        "me",
        JSON.stringify({ ...me.student, submission: me.submission })
      );
      navigate(`/${school.school.url_slug}`);
      setFirstLoad(false);
    }
    if (meError) {
      if (
        meError.response.status === 400 &&
        meError.response.data.message ===
          "Student Not Associated with Requested School"
      ) {
        window.localStorage.setItem(
          "slug",
          window.localStorage.getItem("permanent-slug")
        );
        navigate(
          `/${parseJwt(window.localStorage.getItem("permanent-slug")).sub}`
        );
        window.location.reload();
      } else if (meError.response.status === 401) {
        window.localStorage.removeItem("jwt");
        window.localStorage.removeItem("me");
        setUser(null);
        setFirstLoad(false);
      }
    }
  }, [me, school, meError, setUser]);

  const urlParams = new URLSearchParams(props.location.search);
  const from = urlParams.get("from");
  const isMobile = window.matchMedia("(max-width: 420px)").matches;

  const isValidDate = (dateValue) => {
    return dateValue.length === 8;
  };

  const handleLogin = async (data) => {
    if (isValidDate(date)) {
      executeLogin({
        params: {
          id: school.id,
          lastName: lastName,
          dateOfBirth: formatDateToAPI(date),
          studentId: studentId,
        },
      })
        .then((res) => {
          const jwt = res.headers["x-sis-student-token"];
          const me = res.data;

          setUser({ ...me.student, submission: me.submission });
          window.localStorage.setItem("jwt", jwt);
          window.localStorage.setItem(
            "me",
            JSON.stringify({ ...me.student, submission: me.submission })
          );
          window.localStorage.setItem("loggedIn", "1");
          const axios = Axios.create({
            baseURL: process.env.REACT_APP_BASE_URL,
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          });

          configure({ axios });

          if (from) {
            navigate(`/${from}`);
          } else {
            navigate(`/${school.school.url_slug}`);
          }
        })
        .catch((error) => {
          return;
        });
    } else {
      setWrongDateError(true);
    }
  };

  const handleDateChange = (val) => {
    setDate(val.value);
    if (wrongDateError) {
      setWrongDateError(false);
    }
  };

  const handleStudentIdChange = (val) => {
    setStudentId(val.value);
  };

  const renderWelcomeMessage = useCallback(() => {
    if (school && school.method === "demographics") {
      return (
        <p>
          {`${t("WELCOME_MSG_P1")} ${
            school.school ? school.school.school_insurance_name : ""
          } ${t("WELCOME_MSG_P2")} ${t("WELCOME_MSG_P3")} 
        ${school && school.period ? school.period.academic_year : ""} ${t(
            "WELCOME_MSG_P5"
          )}`}
        </p>
      );
    } else if (school && school.method === "sso") {
      return (
        <p>
          {`${t("WELCOME_SSO_MSG_P1")} ${
            school.school ? school.school.school_name : ""
          } ${t("WELCOME_SSO_MSG_P2")} ${
            school.school ? school.school.school_name : ""
          } ${t("WELCOME_SSO_MSG_P3")}`}
        </p>
      );
    }
  }, [school]);

  const renderCustomLoginMessage = useCallback(() => {
    const sanitizedData = DOMPurify.sanitize(school.login_message, {
      ADD_ATTR: ["target"],
    });
    return (
      <div className="callout callout-warning card-shadowed mb-4">
        <p
          css={css`
            a {
              color: #33cabb !important;
            }
          `}
          dangerouslySetInnerHTML={{ __html: sanitizedData }}
        />
      </div>
    );
  }, [school]);

  const renderCustomCloseMessage = useCallback(() => {
    const sanitizedData = DOMPurify.sanitize(school.closed_message, {
      ADD_ATTR: ["target"],
    });
    return (
      <p
        css={css`
          a {
            color: #33cabb !important;
          }
        `}
        dangerouslySetInnerHTML={{ __html: sanitizedData }}
      />
    );
  }, [school]);

  const isFormDisable = useMemo(() => {
    if (
      mask?.mask?.length !== studentId?.length ||
      date?.length !== 8 ||
      lastName?.length === 0
    ) {
      return true;
    }

    return false;
  }, [mask, studentId, lastName, date]);

  const renderLoginButton = useCallback(() => {
    if (school && school.method === "demographics") {
      return (
        <button
          disabled={isFormDisable}
          className={`${
            isFormDisable ? "disabled" : ""
          } btn btn-bold w-auto ml-auto btn-label btn-block btn-primary mt-50`}
          type="submit"
        >
          <label>
            <i className="ti-check"></i>
          </label>
          {t("LOGIN")}
        </button>
      );
    } else if (school && school.method === "sso") {
      return (
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-bold btn-label btn-primary mt-20"
            onClick={() => window.location.replace(school.auth_config.login)}
          >
            <label>
              <i className="ti-check"></i>
            </label>
            {school.school.school_name} {t("LOGIN")}
          </button>
        </div>
      );
    }
  }, [school, formState, studentIdHasMask, studentId, isFormDisable]);

  const renderLoginError = useCallback(() => {
    if (
      school &&
      school.method === "sso" &&
      props.location.search &&
      props.location.search.includes("error=notfound")
    ) {
      return <LoginError error={{ message: "Currently Not Eligible" }} />;
    }
    if (error && error.response && error.response.data) {
      return <LoginError error={error.response.data} />;
    }
    if (meError && meError.response && meError.response.data) {
      return <LoginError error={meError.response.data} />;
    }
  }, [error, meError, props, school]);

  const errorIcons = useMemo(() => {
    const status = {
      studentId: null,
      lastName: null,
      dob: null,
    };
    if (error && error.response && error.response.data) {
      switch (error.response.data.message) {
        case "Currently Not Eligible":
          status.studentId = "correct";
          status.lastName = "correct";
          status.dob = "correct";
          break;
        case "Student ID Not Recognized":
          status.studentId = "error";
          break;
        case "Your Entries Do Not Match Our Records":
          status.studentId = "correct";
          if (
            !error.response.data.validation.dateOfBirth &&
            !error.response.data.validation.lastName
          ) {
            status.lastName = "error";
            status.dob = "error";
          } else if (
            error.response.data.validation.dateOfBirth &&
            !error.response.data.validation.lastName
          ) {
            status.lastName = "error";
            status.dob = "correct";
          } else if (
            !error.response.data.validation.dateOfBirth &&
            error.response.data.validation.lastName
          ) {
            status.lastName = "correct";
            status.dob = "error";
          }
          break;

        default:
          break;
      }
    }
    return status;
  }, [error]);

  if (firstLoad || !school || !school.school) {
    return <Preloader className="d-flex" />;
  }

  return (
    <Layout withIdle={false} hideFooterResponsive={false}>
      <div
        className="row center-vh my-md-5 mx-auto bg-transparent"
        style={{ maxWidth: "100%" }}
      >
        <div className="col-12 p-0">
          <div className="mx-auto" style={{ maxWidth: "100%", width: "550px" }}>
            {!school.is_closed &&
              school.login_message &&
              renderCustomLoginMessage()}
            <div className="card card-shadowed mb-0 mb-md-5">
              <div className="card-header bg-dark">
                <h5 className="text-white m-0">
                  {school.is_closed ? t("PORTAL_CLOSED") : t("SIGN_IN")}
                </h5>
              </div>
              <div className="card-body">
                {school.is_closed ? (
                  <>
                    {!school.closed_message && (
                      <p>
                        {`${t("CLOSED_MSG_P1")} ${
                          school.school && school.school.school_insurance_name
                            ? school.school.school_insurance_name
                            : school.school.school_name
                        } ${t("CLOSED_MSG_P2")} ${
                          school.school
                            ? school.school.health_office_contact_name
                            : ""
                        } ${t("CLOSED_MSG_P3")}`}{" "}
                        <a
                          href={`mailto:${
                            school.school
                              ? school.school.health_office_email_address
                              : ""
                          }`}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <strong>
                            {school.school
                              ? school.school.health_office_email_address
                              : ""}
                            .
                          </strong>
                        </a>
                      </p>
                    )}
                    {school.closed_message && renderCustomCloseMessage()}
                  </>
                ) : (
                  <div className="text-center mb-50">
                    {renderWelcomeMessage()}
                  </div>
                )}
                {!school.is_closed && (
                  <form
                    onSubmit={handleSubmit(handleLogin)}
                    className="form-type-material"
                  >
                    <div className={`${isMobile ? "p-3" : "px-100"}`}>
                      {school && school.method === "demographics" && (
                        <>
                          <div className="form-group do-float">
                            <div>
                              <CurrencyFormat
                                id="studentID"
                                name="studentID"
                                value={studentId}
                                onValueChange={(val) =>
                                  handleStudentIdChange(val)
                                }
                                className="form-control"
                                format={mask.format}
                                placeholder={mask.mask.join("")}
                                mask={mask.mask}
                              />
                              {errorIcons && errorIcons.studentId && (
                                <div
                                  css={css`
                                    position: absolute;
                                    right: 10px;
                                    top: 15px;
                                    z-index: 2;
                                  `}
                                >
                                  <i
                                    className={`fa ${
                                      errorIcons.studentId === "error"
                                        ? "fa-times text-danger"
                                        : "fa-check text-primary"
                                    }`}
                                  ></i>
                                </div>
                              )}
                            </div>
                            <label
                              htmlFor="studentID"
                              className="label-floated"
                            >
                              {t("STUDENT_ID")}
                            </label>
                          </div>

                          <div className="form-group do-float">
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                id="lastName"
                                name="lastName"
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                              />
                              {errorIcons && errorIcons.lastName && (
                                <div
                                  css={css`
                                    position: absolute;
                                    right: 10px;
                                    top: 15px;
                                    z-index: 2;
                                  `}
                                >
                                  <i
                                    className={`fa ${
                                      errorIcons.lastName === "error"
                                        ? "fa-times text-danger"
                                        : "fa-check text-primary"
                                    }`}
                                  ></i>
                                </div>
                              )}
                            </div>
                            <label htmlFor="lastName" className="label-floated">
                              {t("LAST_NAME")}
                            </label>
                          </div>

                          <div className="form-group do-float">
                            <div>
                              <CurrencyFormat
                                id="dateOfBirth"
                                name="dateOfBirth"
                                value={date}
                                onValueChange={(val) => handleDateChange(val)}
                                className="form-control"
                                format="##/##/####"
                                placeholder="MM/DD/YYYY"
                                mask={["M", "M", "D", "D", "Y", "Y", "Y", "Y"]}
                              />
                              {errorIcons && errorIcons.dob && (
                                <div
                                  css={css`
                                    position: absolute;
                                    right: 10px;
                                    top: 15px;
                                    z-index: 2;
                                  `}
                                >
                                  <i
                                    className={`fa ${
                                      errorIcons.dob === "error"
                                        ? "fa-times text-danger"
                                        : "fa-check text-primary"
                                    }`}
                                  ></i>
                                </div>
                              )}
                            </div>

                            <label
                              htmlFor="dateOfBirth"
                              className="label-floated"
                            >
                              {t("DATE_OF_BIRTH")}
                            </label>
                          </div>
                          {wrongDateError && (
                            <Alert type="danger">{t("USE_VALID_DATE")}</Alert>
                          )}
                        </>
                      )}
                      {renderLoginError()}
                    </div>
                    {renderLoginButton()}
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
