import React, { useContext } from 'react'
import { css } from '@emotion/core'
import moment from 'moment';
import { useTranslation } from 'react-i18next'
import { SchoolStateContext } from '../../context/SchoolContext'

const StepTwo = () => {
  const { t } = useTranslation()
  const { enrollment_segment, period, school } = useContext(SchoolStateContext)

  return (
    <div css={css`
        box-shadow: none;
      `} className="card">
      <div>
      <div css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            margin-bottom: 90px;
            @media (max-width: 768px) {
              margin-bottom: 24px;
            }

            span {
              font-size: 14px;
              color: #4d5259;
              font-weight: normal;
              &.field-name {
                @media(max-width: 420px) {
                  font-size: 10px;
                  color: #a8a9ae;
                }
              }
            }

          `}>
          <span className='field-name fw-500'>{t('ENROLLMENT_PERIOD')}</span>
          <span className="fw-500">{enrollment_segment.segment_label} {period.academic_year}</span>
          <span className='field-name fw-500'>{t('DATES_COVERAGE')}</span>
          <span className="fw-500">{moment(enrollment_segment.coverage_start_date).format('MM/DD/YYYY')} - {moment(enrollment_segment.coverage_end_date).format('MM/DD/YYYY')}</span>
          <span className='field-name fw-500'>{t('COST_PER_SEMESTER')}</span>
          <span className="fw-500">${school.settings_supplement_plan.cost}</span>
        </div>

        <p className="p-body mt-3">
          {t('SUPPLEMENTAL_STEP_TWO_DESCRIPTION_P1')} {school.settings_supplement_plan.plan_title} {t('SUPPLEMENTAL_STEP_TWO_DESCRIPTION_P2')} {school.school_name} {t('SUPPLEMENTAL_STEP_TWO_DESCRIPTION_P3')}
        </p>
      </div>
    </div>
  )
}

export default StepTwo
