import React from "react";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { SchoolStateContext } from "../context/SchoolContext";
import { navigate } from "@reach/router";

// Components
import Layout from '../components/Layout/index';

const Locked = () => {
  const { t } = useTranslation();
  const school = useContext(SchoolStateContext) || {};
  const isMobile = window.matchMedia("(max-width: 420px)").matches;

  const handleLogOut = () => {
    navigate(`/${school.school.url_slug}/log-in`);
  }

  return (
    <Layout withIdle={false} hideFooterResponsive={false}>
      <div className="row center-vh my-md-5 mx-auto bg-transparent" style={{ maxWidth: "100%" }}>
        <div className="col-12 p-0">

          <div className="mx-auto" style={{ maxWidth: "100%", width: "550px" }}>

            <div className="card card-shadowed mb-0 mb-md-5">
              <div className="card-header bg-dark">
                <h5 className="text-white m-0">
                  {t("LOGGED_OUT")}
                </h5>
              </div>
              <div className="card-body">

                <div className={`text-center mb-50 ${isMobile ? "p-3" : "mx-auto"}`}>
                  <p>{t('LOGGED_OUT_INACTIVITY_MESSAGE')} </p>
                </div>
                <div className='d-flex justify-content-end'>
                  <button
                    onClick={handleLogOut}
                    className='btn btn-bold btn-primary btn-label'
                  >
                    <label>
                      <i className='ti-check'></i>
                    </label>
                    {t('CONTINUE')}
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Locked;
