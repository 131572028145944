import React, { useState, useContext, useEffect, useCallback } from "react";
import CurrencyFormat from 'react-currency-format';
import { css } from "@emotion/core";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import useAxios from "axios-hooks";
import { WizardContext } from "../../context/WizardContext";
import { SchoolStateContext } from "../../context/SchoolContext";
import Spinner from "../Spinner";

const StepOne = () => {
  const { t } = useTranslation();
  const [otherOn, setOtherOn] = useState(false);
  const [displayComapanyTitle, setDisplayComapanyTitle] = useState(false);
  const [count, setCount] = useState(0);
  const [phoneError, setPhoneError] = useState(false)

  const school = useContext(SchoolStateContext);
  const { data, setData } = useContext(WizardContext);

  const [providers] = useAxios({
    url: "providers",
    method: "GET",
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
      "X-SIS-SLUG": `${window.localStorage.getItem("slug")}`,
    }
  });

  useEffect(() => {
    if (!data.stepOne.payerId) {
      if (!data.stepOne.insurance.id) {
        setOtherOn(true);
      }
    }
  }, [])

  // check valid
  useEffect(() => {
    if (data) {
      const valid = data.stepOne.memberID !== '' &&
        data.stepOne.phone.length === 10 &&
        ((data.stepOne.payerId && data.stepOne.insurance.name !== '') || (!data.stepOne.payerId && data.stepOne.insurance.name !== ''));
      if (data.valid !== valid) {
        setData(d => ({
          ...d,
          valid
        }));
      }
    }
  }, [data])

  const handleSelectChanged = e => {
    setCount(c => (c + 1))
    if (displayComapanyTitle) {
      setDisplayComapanyTitle(false);
    }
    const option = document.querySelector(`#${e.target.value}`);
    const { id, name } = option.dataset || {};
    if (!id && !name) {
      setOtherOn(true);
      setData({
        ...data,
        stepOne: {
          ...data.stepOne,
          insurance: {
            id: null,
            name: ''
          },
          payerId: '',
        }
      });
      return;
    } else if (otherOn) {
      setOtherOn(false)
    }

    setData({
      ...data,
      stepOne: {
        ...data.stepOne,
        insurance: {
          id,
          name
        },
        payerId: '',
      }
    });
  };

  const setName = e => {
    setData({
      ...data,
      stepOne: {
        ...data.stepOne,
        insurance: {
          name: e.target.value
        }
      }
    });
  };

  const handleStepOneChange = e => {
    setCount(c => (c + 1))
    setData({
      ...data,
      stepOne: {
        ...data.stepOne,
        [e.target.name]: e.target.value
      }
    });
  };

  const handlePhoneChange = e => {
    setCount(c => (c + 1))
    const trimmedPhone = e.trim().replace(/\s+/g, '')
    if (trimmedPhone.length > 0 && trimmedPhone[0] === '1') {
      setPhoneError(true)
      setData({
        ...data,
        stepOne: {
          ...data.stepOne,
          phone: '',
        }
      })
    } else {
      setPhoneError(false)
      setData({
        ...data,
        stepOne: {
          ...data.stepOne,
          phone: e,
        }
      })
    }
  };

  const handleCantFindInsurance = useCallback(() => {
    setCount(c => (c + 1))
    setData(d => ({
      ...d,
      stepOne: {
        ...d.stepOne,
        insurance: {
          name: '',
          id: ''
        },
        payerId: ''
      }
    }))
    setOtherOn(true);
  }, [])

  const handleSelectInsuranceCompany = useCallback(() => {
    setCount(c => (c + 1))
    setData(d => ({
      ...d,
      stepOne: {
        ...d.stepOne,
        insurance: {
          name: '',
          id: ''
        },
        payerId: ''
      }
    }))
    setOtherOn(false);
  }, [])


  return (
    <div className="form-type-material">
      {providers.loading ? (
        <Spinner />
      ) : (
        <>
          {school && school.period && (
            <div className="row">
              <div
                css={css`
                  max-width: 150px;
                `}
                className="col">
                <p className="mb-1">
                  {t("ENROLLMENT_PERIOD")}:
                </p>
                <p className="mb-4">
                  {t("LAST_UPDATED")}:
                </p>
              </div>
              <div className="col">
                <p className="mb-1">
                  {data.period}
                </p>
                <p className="mb-4">
                  {moment(data.lastUpdated).format('MM/DD/YYYY')}
                </p>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12 col-md-6">
              {otherOn &&
                (<div
                  css={css`
                        grid-column: 1/3;
                      `}
                  className="form-group do-float"
                >
                  <input
                    value={data.stepOne.insurance.name}
                    onChange={setName}
                    id="insuranceCustom"
                    className="form-control"
                    type="text"
                    name="insuranceName"
                  />
                  <label className="label-floated" htmlFor="insuranceName">
                    {t("INSURANCE_COMPANY_NAME")}
                  </label>
                </div>
                )}
              <div
                className={`form-group do-float ${otherOn ? 'd-none' : ''}`}
                css={css`
                      grid-column: 1/3;
                      .dropdown-menu {
                        min-width: 100px !important;
                      }
                    `}
                key={count}
              >
                <select
                  name="insuranceId"
                  id="insuranceId"
                  value={data.stepOne.insurance.id || data.stepOne.insurance.id === ''
                    ? `insurance${data.stepOne.insurance.id}`
                    : 'none'}
                  onChange={handleSelectChanged}
                  data-provide="selectpicker"
                  data-live-search="true"
                  className="form-control w-100"
                >
                  <option value="insurance" disabled>{t("SELECT")}</option>
                  {providers.data &&
                    providers.data.map(provider => {
                      return (
                        <option
                          key={provider.id}
                          value={`insurance${provider.id}`}
                          id={`insurance${provider.id}`}
                          data-id={provider.id}
                          data-name={provider.name}
                        >
                          {provider.name}
                        </option>
                      );
                    })}
                  <option value="none" id="none" data-id="" data-name="">
                    {t("OTHER")}
                  </option>
                </select>
                <label className="label-floated" htmlFor="insuranceId">
                  {t("INSURANCE_PROVIDER")}
                </label>
              </div>
            </div>

            <div className="col-12 col-md-6 mb-4 mb-md-0 pl-3 pl-md-0">
              <button
                css={css`
                    align-self: flex-start;
                    white-space: nowrap;
                  `}
                onClick={otherOn ? handleSelectInsuranceCompany : handleCantFindInsurance}
                type="button"
                className="btn btn-bold btn-warning btn-label"
              >
                <label>
                  <i className="fa fa-credit-card"></i>
                </label>
                {otherOn ? t('SELECT_INSURANCE_COMPANY') : t('CANT_FIND_INSURANCE_PROVIDER')}
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-group do-float">
                <input
                  className="form-control"
                  type="text"
                  name="memberID"
                  id="memberID"
                  value={data.stepOne.memberID}
                  onChange={handleStepOneChange}
                />
                <label htmlFor="memberID" className="require">{t("MEMBER")} #</label>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group do-float">
                <CurrencyFormat
                  id="phone"
                  name="phone"
                  value={data.stepOne.phone}
                  onValueChange={val => handlePhoneChange(val.value.trim())}
                  className="form-control"
                  format="+1 ###-###-####"
                  placeholder="+1 000-000-0000"
                  required
                />
                <label htmlFor="phone" className="require">{t("CUSTOMER_SERVICES")} #</label>
              </div>
              <div css={css`
                height: 24px;
                margin-top: -15px;
              `}
              >
                {phoneError && <label className='text-danger'>
                  {t('PHONE_NO_+1')}
                </label>
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-group do-float">
                <input
                  className="form-control"
                  type="text"
                  name="groupNumber"
                  id="groupNumber"
                  value={data.stepOne.groupNumber}
                  onChange={handleStepOneChange}
                />
                <label htmlFor="groupNumber">{t("GROUP")} #</label>
              </div>
            </div>
          </div>
        </>)}
    </div>
  );
};

export default StepOne;
