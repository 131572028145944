import React from 'react'

const Spinner = ({minHeight = true}) => {
  return (
    <div className='center-vh' style={{ minHeight: minHeight ? '300px' : '' }}>
      <svg className='spinner-circle-material-svg' viewBox='0 0 50 50'>
        <circle className='circle' cx='25' cy='25' r='20'></circle>
      </svg>
    </div>
  )
}

export default Spinner
