import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { WizardContext } from '../../context/WizardContext'

const StepFive = () => {
  const { t } = useTranslation();
  const { data } = useContext(WizardContext)

  if (!data) {
    return <></>
  }

  return (
    <div>
      <div className='row card-body'>
        <p className="p-body mb-4 mb-md-3">{t('WAIVER_STEP_5')}</p>
        <div className='col-12 col-md-6 px-0 px-md-3'>
          <div className="h-50px mb-2">
            <small>{t('PAYER_ID')} / EDI #</small>
            <p className='fw-500'>{data.stepOne.payerId}</p>
          </div>

          <div className="h-50px mb-2">
            <small>{t('INSURANCE_PROVIDER')}</small>
            <p className='fw-500'>{data.stepOne.insurance.name}</p>
          </div>

          <div className="h-50px mb-2">
            <small>{t('GROUP_NUMBER')}</small>
            <p className='fw-500'>{data.stepTwo.groupNumber}</p>
          </div>

          <div className="h-50px mb-2">
            <small>{t('MEMBER_ID')}</small>
            <p className='fw-500'>{data.stepTwo.memberID}</p>
          </div>

          <div className="h-50px mb-2">
            <small>{t('CUSTOMER_SERVICE_PHONE')} # </small>
            <p className='fw-500'>{data.stepTwo.phone}</p>
          </div>
        </div>

        <div className='col-12 col-md-6 px-0 px-md-3 pt-4 pt-md-0'>
          {data.stepThree.imageFront && (
            <p>
              <i
                className={`mr-3 text-muted fs-18 fa fa-file-${
                  data.stepThree.imageFront.file_extension === 'pdf'
                    ? 'pdf'
                    : 'photo'
                }-o`}
              ></i>
              <span>{data.stepThree.imageFront.file_name}</span>
            </p>
          )}

          {data.stepThree.imageBack && (
            <p>
              <i
                className={`mr-3 text-muted fs-18 fa fa-file-${
                  data.stepThree.imageBack.file_extension === 'pdf'
                    ? 'pdf'
                    : 'photo'
                }-o`}
              ></i>
              <span>{data.stepThree.imageBack.file_name}</span>
            </p>
          )}

          {data.stepFour.letter && (
            <p>
              <i
                className={`mr-3 text-muted fs-18 fa fa-file-${
                  data.stepFour.letter.file_extension === 'pdf'
                    ? 'pdf'
                    : 'photo'
                }-o`}
              ></i>
              <span>{data.stepFour.letter.file_name}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default StepFive
