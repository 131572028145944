import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { BatchHttpLink } from '@apollo/client/link/batch-http';

const superLink = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }),
  setContext((_, { headers }) => {
    const token = window.localStorage.getItem('jwt') || '';
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }),
  new BatchHttpLink({
    credentials: 'same-origin',
    uri: `${process.env.REACT_APP_GRAPHQL || ''}/`,
  }),
]);
export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: superLink,
});