import Axios from 'axios';
import { navigate } from "@reach/router";


export function logout(urlSlug, setUser, sso, locked) {
  if (sso) {
    Axios.post(sso, {}, {headers: {
      Authorization: `Bearer ${window.localStorage.getItem("jwt")}`
    }}).then(() => {
      setTimeout(() => {
        setUser(null);
      }, 100);
      navigate(`/${urlSlug}/${locked? 'locked' : 'log-in'}`);
      window.localStorage.removeItem("jwt");
      window.localStorage.removeItem("me");
    });
  } else {
    Axios.post(`${process.env.REACT_APP_BASE_URL}/auth/logout`, {}, {headers: {
      Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
      "X-SIS-SLUG": `${window.localStorage.getItem("slug")}`,
    }}).then(() => {
      setTimeout(() => {
        setUser(null);
      }, 100);
      navigate(`/${urlSlug}/${locked? 'locked' : 'log-in'}`);
      window.localStorage.removeItem("jwt");
      window.localStorage.removeItem("me");
    });
  }
}
