import React, {useContext} from "react";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/core";
import { UserContext } from "../../context/UserContext";
import { SchoolStateContext } from "../../context/SchoolContext";

const StepThree = () => {
  const { t } = useTranslation();
  const [{ submission }] = useContext(UserContext);
  const { school } = useContext(SchoolStateContext);
  

  return (
    <div className="tab-pane fade active show">
      <div
        css={css`
          box-shadow: none;
        `}
        className="card my-2"
      >
        <div className="card-body">
          <p>
            {t('UPDATE_WAIVER_STEP_THREE_P1')} {school.school_name} {t('UPDATE_WAIVER_STEP_THREE_P2')} {submission.segment.segment_label} {submission.period.academic_year} {t('UPDATE_WAIVER_STEP_THREE_P3')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StepThree;
