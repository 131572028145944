import React, { useEffect } from "react";
import { Redirect } from "@reach/router";
import useAxios from "axios-hooks";
import * as Sentry from '@sentry/browser';
import Preloader from "../Preloader";

const AuthenticatedRoute = ({ component, path }) => {
  const jwt = window.localStorage.getItem("jwt");
  const slug = window.localStorage.getItem("slug");

  const [{ loading, error, data }, getMe] = useAxios({
    url: "auth/me",
    headers: {
      "Authorization": `Bearer ${jwt}`,
      "X-SIS-SLUG": `${slug}`
    }
  },
    { manual: true }
  );

  useEffect(() => {
    getMe()
  }, [path])

  if (loading) {
    return <Preloader />;
  }

  if (data) {
    window.localStorage.setItem(
      "me",
      JSON.stringify({ ...data.student, submission: data.submission })
    );

    if (process.env.REACT_APP_SENTRY_ENABLE) {
      Sentry.configureScope(scope => scope.setUser({ id: data.student.id, schoolId: data.student.school_id }))
    }

    const slug = window.location.pathname.split('/')[1];
    let supplementalParam = false;
    if (window.location.pathname.includes('supplemental=true')) {
      supplementalParam = true;
    }
    if (path === '/waiver' && data.submission && data.submission.submission_type === "Waiver Application") {
      return <Redirect from={path} to={`/${slug}`} noThrow />
    }
    if (path === '/ship' && data.submission && data.submission.submission_type === "SHIP Enrollment") {
      return <Redirect from={path} to={`/${slug}`} noThrow />
    }
    if (supplementalParam) {
      return <Redirect from={path} to={`/${slug}/supplemental`} noThrow />
    }
    if (path === '/supplemental' && data.submission.has_supplement) {
      return <Redirect from={path} to={`/${slug}`} noThrow />
    }

    if (path === '/supplemental' && !data.submission) {
      return <Redirect from={path} to={`/${slug}`} noThrow />
    }

    if (path === '/supplemental' && !data.submission.has_supplement && data.submission.submission_type === "SHIP Enrollment") {
      return <Redirect from={path} to={`/${slug}`} noThrow />
    }

    if (path === '/update-waiver' &&
      (!data.submission || data.submission.submission_type === "SHIP Enrollment" || data.submission.status !== 'PE')) {
      return <Redirect from={path} to={`/${slug}`} noThrow />
    }

  }


  if (error && !loading) {
    return <Redirect from={path} to="log-in" noThrow />;
  }

  return component;
};

export default AuthenticatedRoute;
