import useAxios from 'axios-hooks'

function useSendFile() {
  const [{data, loading, error}, executePost] = useAxios(
    {
      url: 'waivers/files',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
        "X-SIS-SLUG": `${window.localStorage.getItem("slug")}`,
      },
    },
    {
      manual: true,
    }
  )

  return [{data, loading, error}, executePost]
}

export default useSendFile
