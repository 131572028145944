import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from "@emotion/core";

// Components
import PreAuth from '../components/PreAuth/index';
import Layout from '../components/Layout';

const NotFound = () => {
  const { t } = useTranslation()
  return (
    <Layout withHeader={false}>
      <PreAuth>
        <div className='card-header'>
          <div
            css={css`
            font-size: 16px;
          `}>
            <strong>URL</strong> <span>{t('INCORRECT')}</span>
          </div>
        </div>
        <div className='card-body'>
          <p>
            {t('URL_INCORRECT_DESCRIPTION')}
        </p>
        </div>
      </PreAuth>
    </Layout>
  )
}

export default NotFound
