import React, { useContext } from 'react';

import useAxios from 'axios-hooks';
import { useTranslation } from "react-i18next";
import CardHeader from '../CardHeader';
import { SchoolStateContext } from '../../context/SchoolContext';
import Modal from '../Modal/index';

const CancelModal = ({ display, setDisplay, onCloseModal }) => {
  const { t } = useTranslation();
  const { school } = useContext(SchoolStateContext)
  const [{ error, loading }, executeDelete] = useAxios({
    url: "supplements",
    method: "DELETE",
    headers: {
      "Authorization": `Bearer ${window.localStorage.getItem("jwt")}`,
      "X-SIS-SLUG": `${window.localStorage.getItem("slug")}`,
    }
  },
  {
    manual: true,
  });

  const closeCancelModal = () => {
    setDisplay(false)
  }

  const handleRemove = async () => {
    await executeDelete()
    if (!error && !loading) {
      onCloseModal()
      closeCancelModal()
    }
  }

  return (
    <Modal targetId='cacel-modal' display={display} setDisplay={setDisplay}>
      <CardHeader
        title={t('CANCEL_SUPPLEMENT')}
        rightComponent={(
          <button className='btn border-0 p-0' onClick={closeCancelModal}>
            <i className='fa fa-close'></i>
          </button>
        )} />
      <div className='card-body'>
        <p>
          {t('CANCEL_SUPPLEMENT_PLAN_MSG_P1')} {school.settings_supplement_plan.plan_title} {t('CANCEL_SUPPLEMENT_PLAN_MSG_P2')}
        </p>
        <div className='d-flex'>
          <button
            onClick={handleRemove}
            className='btn d-block ml-auto btn-danger'
          >
            {t('CONFIRM_CANCELLATION')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default CancelModal
