import React from "react";

const Preloader = ({ className = '' }) => {
  return (
    <div className={`preloader ${className}`}>
      <div className="spinner-dots">
        <span className="dot1"></span>
        <span className="dot2"></span>
        <span className="dot3"></span>
      </div>
    </div>
  );
};

export default Preloader;
