import React, { useState, useContext, useEffect } from "react";
import { css } from "@emotion/core";
import useSendFile from "../../hooks/use-sendfile";
import ImageInput from "../ImageInput";
import { WizardContext } from "../../context/WizardContext";
import { useTranslation } from "react-i18next";
import Preloader from '../Preloader/index';

const StepFour = () => {
  const { t } = useTranslation();
  const [letter, setLetter] = useState(null);
  const [{ loading }, executePost] = useSendFile();
  const [letterMetadata, setLetterMetadata] = useState(null);
  const { data, setData } = useContext(WizardContext);
  const [uploadError, setUploadError] = useState(false);

  useEffect(() => {
    if (!!letter) {
      const letterData = new FormData();
      letterData.append("file", letter);
      letterData.append("type", "LETTER");
      executePost({
        data: letterData
      })
        .then(({ data }) => { uploadError && setUploadError(false); setLetterMetadata(data) })
        .catch(err => { setUploadError(true); setLetter(undefined) });
    }
  }, [letter, executePost]);

  useEffect(() => {
    setData({
      ...data,
      stepFour: {
        letter: letterMetadata
      }
    });
  }, [letterMetadata]);

  return (
    <div>
      <p className="p-body mb-4 mb-md-3">{t("WAIVER_STEP_4")}</p>
      <div className="d-flex flex-wrap">
        {loading && <Preloader className="d-flex bg-transparent" />}
        <div className="col-md-6 px-0 px-md-3">
          <p className="text-md-center fw-500">{t("LETTER_ELIGIBILITY")}</p>
          <ImageInput
            id="letter-of-eligibility"
            onImageChange={image => setLetter(image)}
          />
        </div>
      </div>
      {uploadError && (
        <div className="row justify-content-center mt-3">
          <div
            css={css`
            max-width: 350px
          `}
            className="callout callout-danger card-shadowed">
            <p className="mb-2">{t('UPLOAD_IMAGE_ERROR')}</p>
          </div>
        </div>
      )}

    </div>
  );
};

export default StepFour;
