import React, { useContext } from "react";
import { css } from "@emotion/core";
import { useTranslation } from "react-i18next";
import { SchoolStateContext } from '../../context/SchoolContext'

const StepSix = () => {
  const { t } = useTranslation();
  const { school } = useContext(SchoolStateContext);

  return (
    <div className='tab-pane fade active show'>
      <div
        css={css`
          box-shadow: none;
          height: 350px;
        `}
        className='card my-2'
      >
        <p className="p-body">
          {t('WAIVER_FINISH_MSG_P1')} {school.school_name} {t('WAIVER_FINISH_MSG_P2')}
        </p>
      </div>
    </div>
  );
};

export default StepSix;
