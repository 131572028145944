import React, { createContext } from "react";
import useAxios from "axios-hooks";
import { navigate } from "@reach/router";
import Preloader from '../components/Preloader/index';

const SchoolStateContext = createContext(["", undefined]);
const LocalStateProvider = SchoolStateContext.Provider;

function SchoolStateProvider(props) {
  let slug = window.location.pathname;
  slug = slug.split("/")[1];
  

  const [{ data, error, loading ,response }] = useAxios({
    url: "auth/slug",
    params: {
      slug
    }
  });

  if (loading) {
    return <Preloader />;
  }
  
  if(response) {
    window.localStorage.setItem("slug", response.headers['x-sis-slug']);
  }

  if(error) {
    // Error page
    navigate(`/`);
  }

  
  return <LocalStateProvider value={data}>{props.children}</LocalStateProvider>;
}

export { SchoolStateProvider, SchoolStateContext };
