import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';

if (process.env.REACT_APP_SENTRY_ENABLE && process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        beforeSend(event, hint) {
            const response = hint.originalException.response;
            if (
                (response && response.status === 401 && response.config && response.config.url && (
                response.config.url === 'auth/me' || response.config.url === 'auth/demographics' || response.config.url === 'auth/sso' ))
                || (response && response.status === 404 && response.config && response.config.url && (
                    response.config.url === 'providers/payerId'))
            ) {
                return null
            }
            return event;
        }
    });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
