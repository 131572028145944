import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/browser";
import { navigate } from "@reach/router";
import { useLazyQuery } from "@apollo/client";

import StepOne from "../components/Waiver/StepOne";
import StepTwo from "../components/Waiver/StepTwo";
import StepThree from "../components/Waiver/StepThree";
import StepFour from "../components/Waiver/StepFour";
import StepFive from "../components/Waiver/StepFive";
import { SchoolStateContext } from "../context/SchoolContext";
import useAxios from "axios-hooks";
import { UserContext } from "../context/UserContext";
import Layout from "../components/Layout";
import SupplementalCard from "../components/SupplementalCard";
import { logout } from "../services/AuthService";
import StepSix from "../components/Waiver/StepSix";
import Container from "../components/Container";
import Preloader from "../components/Preloader/index";
import WaiverApprovedCard from "../components/Waiver/WaiverApprovedCard";
import CustomWizard from "../components/CustomWizard";
import SubmitErrorModal from "../components/SubmitErrorModal/index";
//import "react-toastify/dist/ReactToastify.css";

// Queries
import {
  students_submissions_ship,
  students_submissions_waiver,
} from "../data/Step2/query";
const emptyWaiverData = {
  step: 0,
  valid: false,
  stepOne: {
    payerId: "",
    insurance: {
      name: "",
      id: "",
      default_pr: false,
    },
  },
  stepTwo: {
    memberID: "",
    groupNumber: "",
    phone: "",
  },
  stepThree: {
    imageFront: "",
    imageBack: "",
  },
  stepFour: {
    letter: null,
  },
};

const Waiver = () => {
  const { t } = useTranslation();
  const [waiverData, setWaiverData] = useState(emptyWaiverData);
  const [user, setUser] = useContext(UserContext);
  const { school } = useContext(SchoolStateContext);
  const [displayWizard, setDisplayWizard] = useState(true);
  const [displayApprovedCard, setDisplayApprovedCard] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const [
    activeShipQuery,
    { error: errorShip, loading: loadingActiveShip, data: activeShip },
  ] = useLazyQuery(students_submissions_ship, { fetchPolicy: "network-only" });

  const [
    cancelledWaiverQuery,
    { loading: loadingCancelledWaiver, data: cancelledWaiver },
  ] = useLazyQuery(students_submissions_waiver, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    ReactGA.pageview("/waiver");
  }, []);

  const [{ loading: loadingWaiver, response, error: errorWaiver }, postWaiver] =
    useAxios(
      {
        url: "waivers",
        method: "POST",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
          "X-SIS-SLUG": `${window.localStorage.getItem("slug")}`,
        },
      },
      {
        manual: true,
      }
    );

  const [{ data, error, loading }, postWaiverApproval] = useAxios(
    {
      url: "waivers/approvals",
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
        "X-SIS-SLUG": `${window.localStorage.getItem("slug")}`,
      },
    },
    {
      manual: true,
    }
  );

  const goHome = useCallback(() => {
    navigate(`/${school.url_slug}`);
  }, [school]);

  const goBack = useCallback(() => {
    setWaiverData((d) => ({
      ...d,
      valid: true,
      step: d.step - 1,
    }));
  }, []);

  const handleNext = useCallback(() => {
    setWaiverData({
      ...waiverData,
      step: waiverData.step + 1,
      valid:
        waiverData.step + 1 > 2
          ? true
          : waiverData.stepTwo.phone.length === 10 &&
            !!waiverData.stepTwo.memberID,
    });
  }, [waiverData]);

  const handleNextStep2 = () => {
    if (
      !data &&
      !error &&
      !waiverData.stepOne.insurance.default_pr &&
      !waiverData.stepOne.insurance.do_not_allow_validation &&
      (waiverData.stepOne.payerId !== "EWAIVE" ||
        (waiverData.stepOne.payerId === "EWAIVE" &&
          waiverData.stepOne.insurance.override_payer_id)) &&
      ((waiverData.stepOne.payerId && waiverData.stepOne.payerId !== "") ||
        (waiverData.stepOne.insurance.id &&
          waiverData.stepOne.insurance.id !== ""))
    ) {
      const payload = {
        memberId: waiverData.stepTwo.memberID,
        insuranceId: waiverData.stepOne.insurance.id,
      };
      postWaiverApproval({ params: payload });
    } else {
      handleNext();
    }
  };

  const handleAutoApproval = useCallback(() => {
    if (
      user.submission &&
      user.submission.submission_type === "SHIP Enrollment"
    ) {
      const waiverForm = {
        insuranceMemberNumber: waiverData.stepTwo.memberID,
        insuranceId: waiverData.stepOne.insurance.id,
      };
      if (user.submission.enrollment_period_id) {
        activeShipQuery({
          variables: {
            studentId: user.id,
            enrollment_period_id: user.submission.enrollment_period_id,
          },
        });
      }
      cancelledWaiverQuery({
        variables: {
          studentId: user.id,
          waiverForm: waiverForm,
          enrollment_period_id: user.submission.enrollment_period_id,
        },
      });
    } else {
      handleNextStep2();
    }
  }, [postWaiverApproval, waiverData]);

  useEffect(() => {
    if (
      !loadingActiveShip &&
      activeShip &&
      !loadingCancelledWaiver &&
      cancelledWaiver
    ) {
      if (
        activeShip.students_submissions.length > 0 &&
        cancelledWaiver.students_submissions.length > 0
      ) {
        setWaiverData((data) => ({
          ...data,
          step: 4,
          valid: true,
        }));
      } else {
        handleNextStep2();
      }
    }
  }, [
    activeShip,
    cancelledWaiver,
    loadingActiveShip,
    loadingCancelledWaiver,
    postWaiverApproval,
  ]);

  const submitWaiver = useCallback(
    (waiverData) => {
      const payload = {
        memberId: waiverData.stepTwo.memberID,
        memberSupportPhone: waiverData.stepTwo.phone,
        groupNumber: waiverData.stepTwo.groupNumber,
        validator: data ? data.validator : null,
        validatorReason: data ? data.message : null,
        validatorId: data ? data.id : null,
      };

      if (waiverData.stepOne.payerId) {
        payload.payerId = waiverData.stepOne.payerId;
      }
      if (waiverData.stepOne.insurance.id) {
        payload.insuranceId = waiverData.stepOne.insurance.id;
      } else if (waiverData.stepOne.insurance.name) {
        payload.payerName = waiverData.stepOne.insurance.name;
      }
      postWaiver({
        params: payload,
      });

      ReactGA.event({
        category: "User",
        action: "Press Submit Waiver",
      });
    },
    [data, postWaiver]
  );

  const supplementalPlan = useMemo(
    () =>
      school.supplement_plan_enabled &&
      school.settings_supplement_plan.enrollments_enabled,
    [school]
  );

  const handleFinish = useCallback(() => {
    if (supplementalPlan) {
      setDisplayApprovedCard(false);
      setDisplayWizard(false);
    } else {
      logout(
        school.url_slug,
        setUser,
        school.method === "sso" ? school.auth_config.logout : null
      );
    }
  }, [supplementalPlan, school, setUser]);

  const waiverItems = useMemo(
    () => [
      {
        title: "1",
        id: "first-step",
        content: <StepOne />,
        backButton: (
          <button className="btn btn-secondary" onClick={goHome}>
            {t("BACK")}
          </button>
        ),
        nextButton: (
          <button
            className="btn btn-secondary"
            onClick={handleNext}
            disabled={!waiverData || (waiverData && !waiverData.valid)}
          >
            {t("NEXT")}
          </button>
        ),
      },
      {
        title: "2",
        id: "second-step",
        content: <StepTwo loading={loading} />,
        backButton: (
          <button className="btn btn-secondary" onClick={goBack}>
            {t("BACK")}
          </button>
        ),
        nextButton: (
          <button
            className="btn btn-secondary"
            onClick={handleAutoApproval}
            disabled={
              !waiverData || (waiverData && !waiverData.valid) || loading
            }
          >
            {t("NEXT")}
          </button>
        ),
      },
      {
        title: "3",
        id: "third-step",
        content: <StepThree />,
        backButton: (
          <button className="btn btn-secondary" onClick={goBack}>
            {t("BACK")}
          </button>
        ),
        nextButton: (
          <button
            className="btn btn-secondary"
            onClick={handleNext}
            disabled={!waiverData || (waiverData && !waiverData.valid)}
          >
            {t("NEXT")}
          </button>
        ),
      },
      {
        title: "4",
        id: "fourth-step",
        content: <StepFour />,
        backButton: (
          <button className="btn btn-secondary" onClick={goBack}>
            {t("BACK")}
          </button>
        ),
        nextButton: (
          <button
            className="btn btn-secondary"
            onClick={handleNext}
            disabled={!waiverData || (waiverData && !waiverData.valid)}
          >
            {t("NEXT")}
          </button>
        ),
      },
      {
        title: "5",
        id: "fifth-step",
        content: <StepFive />,
        backButton: (
          <button className="btn btn-secondary" onClick={goBack}>
            {t("BACK")}
          </button>
        ),
        nextButton: (
          <button
            className="btn btn-primary btn-label"
            onClick={() => submitWaiver(waiverData)}
            disabled={!waiverData || (waiverData && !waiverData.valid)}
          >
            <label>
              <i className="ti-check"></i>
            </label>
            {t("SUBMIT_WAIVER_APPLICATION")}
          </button>
        ),
      },
      {
        title: "6",
        id: "sixth-step",
        content: <StepSix />,
        backButton: null,
        nextButton: (
          <button
            className={`btn ${
              supplementalPlan ? "btn-primary" : "btn btn-secondary"
            }`}
            onClick={handleFinish}
            disabled={!waiverData || (waiverData && !waiverData.valid)}
          >
            {supplementalPlan
              ? t("REVIEW_OPTIONAL_SUPPLEMENT_PLAN")
              : t("LOG_OUT")}
          </button>
        ),
      },
    ],
    [
      goHome,
      t,
      handleNext,
      waiverData,
      loading,
      goBack,
      data,
      error,
      handleAutoApproval,
      supplementalPlan,
      handleFinish,
      submitWaiver,
    ]
  );

  // Track /waivers error in sentry
  useEffect(() => {
    if (errorWaiver && process.env.REACT_APP_SENTRY_ENABLE) {
      const payload = {
        memberId: waiverData.stepTwo.memberID,
        memberSupportPhone: waiverData.stepTwo.phone,
        groupNumber: waiverData.stepTwo.groupNumber,
        validator: data ? data.validator : null,
        validatorReason: data ? data.message : null,
        validatorId: data ? data.id : null,
      };

      if (waiverData.stepOne.payerId) {
        payload.payerId = waiverData.stepOne.payerId;
      }
      if (waiverData.stepOne.insurance.id) {
        payload.insuranceId = waiverData.stepOne.insurance.id;
      } else if (waiverData.stepOne.insurance.name) {
        payload.payerName = waiverData.stepOne.insurance.name;
      }
      Sentry.captureMessage(
        `Post /waivers 
      -- Payload${JSON.stringify(payload)}
      -- Error: ${JSON.stringify(errorWaiver)}`,
        {
          extra: {
            payload,
            error: errorWaiver,
          },
        }
      );
    }
  }, [errorWaiver, waiverData, data]);

  useEffect(() => {
    if (response && data && data.approved && !error) {
      setDisplayApprovedCard(true);
      setDisplayWizard(false);
    }
  }, [data, error, response]);

  useEffect(() => {
    if (response && !errorWaiver && !loadingWaiver) {
      setWaiverData((d) => ({
        ...d,
        step: d.step + 1,
      }));
    } else if (errorWaiver && !loadingWaiver) {
      setErrorModal(true);
    }
  }, [response, errorWaiver, loadingWaiver]);

  // When autoapproval fails move to next step
  useEffect(() => {
    if (
      (error && !loading && !data) ||
      (!loading && !error && data && !data.approved)
    ) {
      handleNext();
    }
  }, [error, data, loading]);

  useEffect(() => {
    if (data && data.approved && !error && !loading) {
      const payload = {
        memberId: waiverData.stepTwo.memberID,
        memberSupportPhone: waiverData.stepTwo.phone,
        groupNumber: waiverData.stepTwo.groupNumber,
        validator: data ? data.validator : null,
        validatorReason: data ? data.message : null,
        validatorId: data ? data.id : null,
      };

      if (waiverData.stepOne.payerId) {
        payload.payerId = waiverData.stepOne.payerId;
      }
      if (waiverData.stepOne.insurance.id) {
        payload.insuranceId = waiverData.stepOne.insurance.id;
      } else if (waiverData.stepOne.insurance.name) {
        payload.payerName = waiverData.stepOne.insurance.name;
      }
      postWaiver({
        params: payload,
      });
    }
  }, [data, error, loading]);

  return (
    <Layout hideLogoInMobile>
      <Container>
        <SubmitErrorModal
          display={errorModal}
          setDisplay={setErrorModal}
          title={t("WAIVER_SUBMISSION_NOTIFICATION")}
          description={t("WAIVER_SUBMISSION_ERROR")}
        />
        {loadingWaiver && <Preloader className="d-flex bg-transparent" />}
        {displayWizard ? (
          <CustomWizard
            title={t("WAIVER_APPLICATION")}
            items={waiverItems}
            context={[waiverData, setWaiverData]}
          />
        ) : (
          <>
            {displayApprovedCard ? (
              <WaiverApprovedCard />
            ) : (
              <SupplementalCard />
            )}
          </>
        )}
      </Container>
    </Layout>
  );
};

export default Waiver;
