import React from "react";
import { css } from '@emotion/core';

const CardHeader = ({ title, rightComponent = null, bold = null }) => {
  let strongWord = '';
  let rest = ''
  if (bold) {
    strongWord = bold;
  } else {
    strongWord = title.split(' ')[0];
    rest = title.split(' ').length > 1 ? title.substr(title.indexOf(" ") + 1) : '';
  }

  return (
    <div
      css={css`@media (max-width: 768px) {
          background-color: #3d4554;
          border-radius: 0 !important;
        }`}
      className="card-header">
      <div className="row w-100 justify-content-between">
        <h4
          css={css`@media (max-width: 768px) {
          color: #f5f6fa;
        }`}
          className="card-title fw-500">
          {strongWord} <span className="fw-300">{rest}</span>
          
        </h4>
        {rightComponent}
      </div>
    </div>
  );
};

export default CardHeader;
