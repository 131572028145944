import React, { useContext, useEffect, useState } from "react";
import { WizardContext } from "../../context/WizardContext";
import ImageInput from "../ImageInput";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/core";
import useSendFile from "../../hooks/use-sendfile";
import Preloader from '../Preloader/index';

const StepTwo = () => {
  const { t } = useTranslation();
  const { data, setData } = useContext(WizardContext);
  const [imageFront, setImageFront] = useState();
  const [imageBack, setImageBack] = useState();
  const [letterMode, setLetterMode] = useState(false);
  const [frontImageMetadata, setFrontImageMetadata] = useState();
  const [backImageMetadata, setBackImageMetadata] = useState();
  const [letter, setLetter] = useState(null);
  const [letterMetadata, setLetterMetadata] = useState(null);
  const [{ loading }, executePost] = useSendFile();
  const [uploadError, setUploadError] = useState(false);

  useEffect(() => {
    if (!!letter) {
      const letterData = new FormData();
      letterData.append("file", letter);
      letterData.append("type", "LETTER");
      executePost({
        data: letterData
      })
        .then(({ data }) => {uploadError && setUploadError(false); setLetterMetadata(data)})
        .catch(err => { setUploadError(true); setLetter(undefined) });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [letter, setUploadError, uploadError]);

  useEffect(() => {
    let frontImageData = new FormData();
    frontImageData.append("file", imageFront);
    frontImageData.append("type", "CARD_FRONT");


    if (!!imageFront) {
      executePost({ data: frontImageData })
        .then(({ data }) => {uploadError && setUploadError(false); setFrontImageMetadata(data)})
        .catch(err => { setUploadError(true); setImageFront(undefined) });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageFront, setUploadError, uploadError]);

  useEffect(() => {

    let backImageData = new FormData();
    backImageData.append("file", imageBack);
    backImageData.append("type", "CARD_BACK");

    if (!!imageBack) {
      executePost({ data: backImageData })
        .then(({ data }) => {uploadError && setUploadError(false);setBackImageMetadata(data)})
        .catch(err => {setUploadError(true); setImageBack(undefined) });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageBack, setUploadError, uploadError]);

  useEffect(() => {
    setData({
      ...data,
      stepTwo: {
        valid: frontImageMetadata || backImageMetadata || letterMetadata,
        imageFront: frontImageMetadata,
        imageBack: backImageMetadata,
        letter: letterMetadata
      }
    });
    //eslint-disable-next-line
  }, [frontImageMetadata, backImageMetadata, letterMetadata]);

  return (
    <div>
      {loading && <Preloader className="d-flex bg-transparent" />}
      { letterMode && <p>{t("WAIVER_STEP_4")}</p> }
      { !letterMode && <p>{t("WAIVER_STEP_3")}</p> }
      <div className={`${!letterMode ?  'row' : 'd-none'} flex-wrap"`}>
        <div className="col-12 col-md-6">
          <p className="text-center text-bold">
            <strong>{t("FRONT_OF_CARD")}</strong>
          </p>
          <ImageInput
            id="medical-insurance-front"
            onImageChange={image => setImageFront(image)}
          />
        </div>
        <div className="col-12 col-md-6">
          <p className="text-center text-bold">
            <strong>{t("BACK_OF_CARD")}</strong>
          </p>
          <ImageInput
            id="medical-insurance-back"
            onImageChange={image => setImageBack(image)}
          />
        </div>
      </div>
      <div className={`${letterMode ?  'row' : 'd-none'} flex-wrap"`}>
        <div className="col-12">
          <p className="text-center text-bold">
            <strong>{t("LETTER_OF_ELIGIBILITY")}</strong>
          </p>
          <ImageInput
            id="letter-of-eligibility"
            onImageChange={image => setLetter(image) }
          />
        </div>
      </div>
      {uploadError && (
        <div className="row justify-content-center mt-3">
          <div
            css={css`
            max-width: 350px
          `}
            className="callout callout-danger card-shadowed">
            <p className="mb-2">{t('UPLOAD_IMAGE_ERROR')}</p>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-12 text-center text-md-right">
          <button
            onClick={() => setLetterMode(!letterMode)}
            type="button"
            className="btn btn-bold my-3 btn-warning btn-label"
          >
            <label>
              <i className="fa fa-credit-card"></i>
            </label>
            {letterMode
              ? t("RETURN_TO_UPLOAD_ID_CARDS")
              : t("MY_INSURANCE_DOES_NOT_HAVE_ID_CARDS")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
