import React, { useState, createContext } from 'react'

const UserContext = createContext(['', undefined])
const LocalStateProvider = UserContext.Provider

function UserProvider(props) {
  const [user, setUser] = useState(
    JSON.parse(window.localStorage.getItem('me') || '{}')
  )

  return (
    <LocalStateProvider value={[user, setUser]}>
      {props.children}
    </LocalStateProvider>
  )
}

export { UserProvider, UserContext }
